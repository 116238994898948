import React, { useState, useEffect } from 'react'
import Dowload from "../../asset/img/download.png";
import Up from "../../asset/img/up.png";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TableList from '../../component/main/table';
import { setModalPay, setStatePayModal ,setRate, setChargeDescription, setSendMoney, setLoadingHistory} from "../../redux/accction/acction";
import { useSelector, useDispatch } from 'react-redux';
import APILink from '../../service/APILink';
import Cookies from 'js-cookie';
import {formatPrice} from '../../service/funWeb';
import { isNumber } from 'highcharts';
import { toast } from 'react-toastify';
function PrimaryPay() {
    const dispatch = useDispatch();
    
    const token = Cookies.get('token');
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [nextPage, setNextPage] = useState(null);
    const [listdata, setListdata] = useState(null);
    const [type, setType] = useState(false);
    const [typeLable, setTypeLable] = useState(['Thời gian', "Trạng thái",'số tiền', 'Trạng thái']);
    const balanceUser = useSelector((state) => state.reducers.balanceUser);
    const loaddingHistiry = useSelector((state) => state.reducers.loaddingHistiry);

    const rate = useSelector((state) => state.reducers.rate);
        useEffect(()=>{
          getInforPayModal();
        
      },[])
      const getInforPayModal = () => {
        APILink.get('recharge')
          .then((response) => {
            if (response.data.status === "success") {
              dispatch(setRate(response.data.rate));
              dispatch(setChargeDescription(response.data.charge_description));
              dispatch(setSendMoney(response.data.send_money));
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    useEffect(() => {

        if (token && token !== null) {
            getListHistoryTransactionFun();
        }
    }, [limit, type, token]);

    const getListHistoryTransactionFun = () => {
        dispatch(setLoadingHistory(true));
        setLoading(true)
        APILink.get(`history-transation?page=1&limit=${limit}&type=${type}`)
            .then((response) => {
                if (response.data.status === "success") {
                    dispatch(setLoadingHistory(false))
                    setListdata(response.data.result.data);
                    setNextPage(response.data.result.next_page_url);
                }
            })
            .catch((error) => {
                console.error(error);
            }).finally(()=>{
                setLoading(false)
            });
    }

    const nextPageFun = () => {
        loadMoreAcctionFun(page + 1, limit, type)
    }
    const changeTab =(e) =>{
        if(loading === false){
            setType(e === "recharge" ? false : true)
        }
    }
    const loadMoreAcctionFun = (page, limit, type) => {
       
        setLoad(true)
        APILink.get(`history-transation?page=${page}&limit=${limit}&type=${type}`)
            .then((response) => {
                if (response.data.status === "success") {
                    setListdata(prevListData => [
                        ...prevListData,
                        ...response.data.result.data
                    ]);
                    setNextPage(response.data.result.next_page_url);
                    setPage(response.data.result.current_page);
                    setLoad(false)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const rechargeFun = () => {
        dispatch(setStatePayModal(false));
        dispatch(setModalPay(true));
    }
    const withdrawWoneyFun = () => {
        dispatch(setModalPay(true));
        dispatch(setStatePayModal(true));
    }

    return (
        <div>
            <h4 className='mb-4'>Tài khoản chính:</h4>
            <div className='primary-pay-box p-3'>
                <div>
                    <div className='flex-class justify-content-between w-100'>
                        <div className='flex-class justify-content-start'>
                            <svg width='44px' height='44px' viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M617.1 671.2H148.2c-46.3 0-83.9-37.6-83.9-83.9v-307c0-46.3 37.6-83.9 83.9-83.9h468.9c46.3 0 83.9 37.6 83.9 83.9v306.9c0 46.4-37.5 84-83.9 84z" fill="#248CBE"></path><path d="M64.3 302.3h636.8v96.1H64.3z" fill="#183351"></path><path d="M304 531.8H128.3c-12.1 0-22-9.9-22-22s9.9-22 22-22H304c12.1 0 22 9.9 22 22s-9.9 22-22 22zM437.4 601.2H128.3c-12.1 0-22-9.9-22-22s9.9-22 22-22h309.1c12.1 0 22 9.9 22 22s-9.9 22-22 22z" fill="#FBFAEE"></path><path d="M875.8 827.6H406.9c-46.3 0-83.9-37.6-83.9-83.9v-307c0-46.3 37.6-83.9 83.9-83.9h468.9c46.3 0 83.9 37.6 83.9 83.9v306.9c0 46.4-37.5 84-83.9 84z" fill="#F5DB6F"></path><path d="M788.3 607.2H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h401.3c12.1 0 22 9.9 22 22s-9.9 22-22 22z" fill="#AEB8C4"></path><path d="M562.7 688.2H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h175.8c12.1 0 22 9.9 22 22-0.1 12.1-10 22-22.1 22zM696.1 757.6H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h309.1c12.1 0 22 9.9 22 22s-9.9 22-22 22z" fill="#FBFAEE"></path><path d="M510.4 520.3H401.8c-20.3 0-36.8-16.5-36.8-36.8v-50.8c0-20.3 16.5-36.8 36.8-36.8h108.6c20.3 0 36.8 16.5 36.8 36.8v50.8c0 20.3-16.5 36.8-36.8 36.8z" fill="#F3C262"></path><path d="M547.2 438.1h-34.3v-42.2H479v124.4h33.9v-47.7h34.3zM402.6 395.9v42.2H365v34.5h37.6v47.7h33.9V395.9z" fill="#F7A04D"></path><path d="M959.7 577.8V484c-80.6-0.3-148.2-56.4-166.1-131.6h-95.5c19.2 127.2 129.2 225.1 261.6 225.4z" fill="#F7F9DD"></path></g></svg>
                            <div className='ms-3'>
                                <p className='text-uppercase'> <span className='font-weight-bold'>Ngân Hàng</span>  VNĐ</p>
                                <p> VietNamDong </p>
                            </div>
                        </div>
                        <div className='flex-class justify-content-end'>
                            <div className='text-end'>
                                <span className='text-uppercase'>${balanceUser}</span><br />
                                <span> ~VND { balanceUser !== null && balanceUser >0  ? formatPrice(balanceUser*rate) : 0 } </span>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div className='flex-class justify-content-between w-100'>
                        <div className='flex-class justify-content-start' onClick={rechargeFun}>
                            <img style={{ width: "18px" }} src={Dowload} />
                            <p className='ms-2'> Nạp tiền </p>
                        </div>
                        <div className='flex-class justify-content-end' onClick={withdrawWoneyFun} >
                            <img style={{ width: "18px" }} src={Up} />
                            <p className='ms-2'> Rút tiền </p>
                        </div>

                    </div>
                </div>
            </div>
            <h4 className='mb-4 mt-3'>Lịch sử giao dịch</h4>
            <div>
                <Tabs
                    defaultActiveKey={type === false ? "recharge" : "withdraw"}
                    id="uncontrolled-tab-example"
                    className="mb-1"
                    onSelect={(e) => changeTab(e) }
                >
                    <Tab eventKey="recharge" title="Nạp" className='mt-5'>
                        <TableList titlerLable={typeLable} data={listdata} type={type} />
                    </Tab>
                    <Tab eventKey="withdraw" title="Rút" className='mt-5'>
                        <TableList titlerLable={typeLable} data={listdata} type={type} />
                    </Tab>
                </Tabs>
                {nextPage && nextPage !== null && loaddingHistiry === false ?
                    (<div className='flex-class justify-content-center w-100 mt-3 '>
                        <button className='btn-load-more' onClick={nextPageFun} disabled={load}>
                            {load === true ? 'Đang xử lý...' : 'Xem thêm'}  </button>
                    </div>)
                    : null
                }

            </div>

        </div>
    )
}

export default PrimaryPay

import React from 'react'
import MainLogin from '../component/login/main';
import HeaderLogin from '../component/login/header';
import Footer from '../component/login/footer';
function login() {
  return (
    <div className='w-100 p-0 ps-lg-5 pe-lg-5 login-container mt-3'>
      <div className='header'>
         <HeaderLogin/>
      </div>
      <div className='mt-2 mt-lg-3'>
          <MainLogin/>
      </div>
      <footer>
         <Footer/>
      </footer>
    </div>
  )
}

export default login
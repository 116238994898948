import React from 'react';
import Table from 'react-bootstrap/Table';

function TableComponent({ titlerLable, data}) {

    return (
        <Table>
            <thead>
                <tr>
                    {titlerLable && titlerLable.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data && data.length > 0 ? (
                    data.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <td>{new Date(item.created_at).toLocaleString()}</td>
                            </td>
                            <td>{item.coin_code}</td>
                            <td>$ {item.amount}</td>
                            <td> {item.status === 2 ? "Thua" : "Thắng" }</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className='text-center'>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default TableComponent;

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLogin, setModalRegister, setUser , setBalanceUser } from "../../redux/accction/acction";
import APILink from '../../service/APILink';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const show = useSelector((state) => state.reducers.modalRegister);
  const [load, setLoad] = useState(false);

  const handleClose = () => dispatch(setModalRegister(false));
  
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Bạn không được bỏ trống tên đăng nhập'),

    password: Yup.string()
      .required('Bạn không được bỏ trống mật khẩu'),
      apassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Mật khẩu và xác nhận mật khẩu không khớp')
      .required('Bạn không được bỏ trống xác nhận mật khẩu'),
  });

  const handleSubmit = (values) => {
    setLoad(true)
    APILink.post('register', values)
      .then((response) => {
        if (response.data.status === "success") {
          Cookies.set('token', response.data.token, { expires: 30, secure: true, sameSite: 'Strict' });          
          toast.success(response.data.message);
          handleClose();
          setTimeout(() => {
            navigate('/home'); 
          }, 800);
        }
        else{toast.error(response.data.message);
       
        }
      })
      .catch((error) => {
        toast.error('Đăng ký thất bại, kiểm tra lại tài khoản hoặc mật khẩu', { type: 'error' });
        console.error(error);
      });
      setLoad(false)
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className='login-form pt-4 pb-4 register-form'>
        <div className='w-100 h-100 position-relative'>
          <div className='icon-close-login-form flex-class justify-content-center' onClick={() => dispatch(setModalRegister(false))}>
              <i className="fa-solid fa-xmark"></i>
          </div>
          <Modal.Header className='pt-4 pb-4'>
            <Modal.Title>Đăng ký</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{ name: '', password: '', apassword: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >

              {() => (
                <FormikForm noValidate>
                  <Row className="mb-3" >
                    <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                      <Form.Label>Tên đăng nhập</Form.Label>
                      <Field
                        type="text"
                        name="name"
                        as={Form.Control}
                        className='input-login-form'
                        placeholder='Nhập gmail/Số điện thoại'
                      />
                      <ErrorMessage name="name" component="div" className="invalid-feedback d-block" />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                      <Form.Label>Mật khẩu</Form.Label>
                      <Field
                        type="password"
                        name="password"
                        as={Form.Control}
                        className='input-login-form'
                         placeholder='Nhập mật khẩu'
                      />
                      <ErrorMessage name="password" component="div" className="invalid-feedback d-block" />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                      <Form.Label>Xác nhận mật khẩu</Form.Label>
                      <Field
                        type="password"
                        name="apassword"
                        as={Form.Control}
                        className='input-login-form'
                         placeholder='Nhập xác nhận mật khẩu'
                      />
                      <ErrorMessage name="apassword" component="div" className="invalid-feedback d-block" />
                    </Form.Group>
                  </Row>
                  <Button variant="primary" type="submit" className='btn-login-form bg-transparent mt-4' disabled={load}>
                  {load === true ? 'Đang xử lý...' : 'Đăng ký'}
                  </Button>
                </FormikForm>
              )}
            </Formik>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Register;

import React from 'react'
import LogoLogin from "../../asset/img/logo_en-20240323082117-rpnpr.gif";
import { useSelector, useDispatch } from 'react-redux';
import {setModalLogin, setModalRegister} from "../../redux/accction/acction";

function HeaderLogin() {
    const dispatch = useDispatch();
  return (
    <div className='flex-class justify-content-between w-100 h-100'>
        <div className='flex-class justify-content-start h-100'>
        <div className='logo-login h-100'>
            <img className='h-100' src={LogoLogin}/>
        </div>
        <div>
            <ul className='d-none d-lg-flex navigation flex-class justify-content-start'>
                <li className='actice' >
                    <a>
                        Về JPX
                    </a>
                </li>
                <li className='ms-2'>
                    <a>
                        Thị trường
                    </a>
                </li>
                <li className='ms-2'>
                    <a>
                       Giao dịch
                    </a>
                </li>
                <li className='ms-2'>
                    <a>
                        Kết nối
                    </a>
                </li>
            </ul>
        </div>
        </div> 
        
        <div>
        <ul className='navigation flex-class justify-content-end '>
                <li >
                    <p  onClick={()=>dispatch(setModalRegister(true))}>
                       Đăng ký
                    </p>
                </li>
                <li className='ms-3 actice' onClick={()=>dispatch(setModalLogin(true))}>
                    <p>
                       Đăng nhập
                    </p>
                </li>
                </ul>
        </div>
    </div>
  )
}

export default HeaderLogin
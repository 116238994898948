import React, { useState, useEffect } from 'react';
import APILink from '../service/APILink';
import { toast } from 'react-toastify';
import { formatDate } from "../service/funWeb";
import Pusher from 'pusher-js';
import Cookies from 'js-cookie';
function Chat() {
    const [id, setId] = useState(0);
    const [user_id, setuserId] = useState(0);
    const [data, setData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [listmessageChat, setListmessageChat] = useState([]);
    const [message, setMessage] = useState('');
    const [activeUserId, setActiveUserId] = useState(0);
    const [image, setImage] = useState(null); 
    const [idChat, setIdChat] = useState(0);
    const [isTokenSet, setIsTokenSet] = useState(false);
    useEffect(() => {
        callAPiFun();
    }, []);
   
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');    
        if (token && !Cookies.get('token')) {
            Cookies.set('token', token, { expires: 30 }); 
            setIsTokenSet(true);
        } else if (Cookies.get('token')) {
            setIsTokenSet(false);
        }
    }, []);
    
    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            encrypted: true
        });
    
        const channel = pusher.subscribe(`chat.${idChat}`);
     
      
        channel.bind('message-event', function(data) {
            setListmessageChat(prevMessages => [...prevMessages, data.message]);
        });
    
        return () => {
            pusher.unsubscribe(`chat.${idChat}`);
        };
    }, [idChat]);
   
    const callAPiFun = () => {
        const config = isTokenSet ? {
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        } : {};
        APILink.get('list-user-chat',config)
            .then((response) => {
                if (response.data.status === 'success') {
                    const users = response.data.result;
                    setData(users);
                    setIsAdmin(response.data.isAdmin);
                    const firstUser = users[0];
                    setActiveUserId(firstUser.id);
                                    
                    setId(response.data.user_id);

                    if(response.data.isAdmin === true){
                        setListmessageChat( firstUser.messages.length > 0 ? firstUser.messages : []);
                        setuserId(firstUser.id)   ;
                        setIdChat( firstUser.id)
                    }else{
                        setListmessageChat(firstUser.messages || []);
                        setIdChat(response.data.user_id);
                        setuserId(firstUser.id)    
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
   
    useEffect(()=>{
        if(user_id > 0){
            callGetListChat(user_id);
        }
    },[user_id]);
    const callGetListChat = (user_id) => {
        const config = isTokenSet ? {
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        } : {};
        APILink.get(`get-content-chat?id=${user_id}`, config)
            .then((response) => {
                if (response.data.status === 'success') {
                   setListmessageChat(response.data.result);
                   //scroll to .list-message-chat bottom
                    const listMessageChat = document.querySelector('.list-message-chat');
                    listMessageChat.scrollTop = listMessageChat.scrollHeight

                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const postMessage = () => {
        if (!message.trim() && !image) {
            toast.error("Please enter a message or upload an image.");
            return;
        }
    
        const formData = new FormData();
        formData.append('receiver_id', user_id);
    
        if (message.trim()) {
            formData.append('message', message);
        }
    
        if (image) {
            formData.append('file', image);
        }
    
        const url = image !== null ? "send-Image" : "send-message";
        APILink.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.data.status === 'success') {
                setMessage('');
                setImage(null);
                toast.success("Gửi tin nhắn thành công")
            } else {
                toast.error(response.data.message || "Failed to send message.");
            }
        })
        .catch((error) => {
            console.error(error);
            toast.error("An error occurred. Please try again.");
        });
    };
    

    const handleUserClick = (userId) => {
        setuserId(userId);
        const selectedUser = data.find(user => user.id === userId);
        if (selectedUser) {
            setListmessageChat(selectedUser.messages.length > 0 ? selectedUser.messages : []);
        }
        setActiveUserId(userId);
        setIdChat(userId)
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    return (
        <div className='row'>
            <div className='text-center mb-4'>
                <h1>
                    Chăm sóc khách hàng
                </h1>
            </div>
            {isAdmin && (
                <div className={`col-12 col-lg-4 ${isAdmin ? "d-block" : "d-none"}`}>
                    <h1>{isAdmin ? "Chăm sóc khách hàng" : "Nhắn tin với quản trị viên"}</h1>
                    <ul className='container w-100 p-3 list-user-chat'>
                        {data && data.length > 0 && data.map((item) => (
                            <li
                                key={item.id}
                                className={`p-4 d-flex align-items-stretch ${activeUserId === item.id ? "active" : ""}`}
                                onClick={() => handleUserClick(item.id)}
                            >
                                <div>
                                    <img className='img-chat' src={process.env.REACT_APP_SERVER_URL + item.avatar} alt="Avatar" />
                                </div>
                                <div className='ms-3'>
                                    <div>
                                        <p className='fs-7'>{item.name}</p>
                                        <p>{item.messages.length > 0 ? formatDate(item.messages[0].created_at) : "Không có dữ liệu"}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className={`col-12 ${isAdmin ? "col-lg-8" : ""}`}>
                <div className='chat-infor w-100'>
                    <div className='list-message-chat w-100 d-block p-3 d-flex flex-column'>
                        {listmessageChat && listmessageChat.length > 0 && listmessageChat.map((item) => (
                            <div
                                key={item.id}
                                className={`p-3 item-message ${item.user_id !== id ? "align-self-start" : "align-self-end"}`}
                            >
                                <div>
                                    <p className='fs-7 text-end'>{formatDate(item.created_at)}</p>
                                    <p className='fs-6'>
                                        {item.type === "image" ? (
                                            <img className='w-100' src={process.env.REACT_APP_SERVER_URL + item.content} alt="Img" />
                                        ) : (
                                            item.content
                                        )}
                                    </p>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className='box-send-message p-2 p-lg-4'>
                        <div>
                            <textarea
                                className='form-control w-100'
                                style={{ height: "200px" }}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="form-control mt-2"
                            />
                            <div className='flex-class justify-content-between mt-3'>
                                <div></div>
                                <button onClick={postMessage} className='btn btn-primary'>
                                    Gửi tin nhắn
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chat;

import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { Helmet } from 'react-helmet';

// function ChartComponent() {
//     const chartContainerRef = useRef(null);
//
//     useEffect(() => {
//         let randomFactor = 0.1 + Math.random() * 0.8;
//         const samplePoint = i =>
//             i * (0.5 + Math.sin(i / 1) * 0.2 + Math.sin(i / 2) * 0.4 + Math.sin(i / randomFactor) * 0.8 + Math.sin(i / 50) * 0.5) + 200 + i * 2;
//
//         function generateData(numberOfCandles = 500, updatesPerCandle = 5, startAt = 100) {
//             const createCandle = (val, time) => ({
//                 time,
//                 open: val,
//                 high: val,
//                 low: val,
//                 close: val,
//             });
//
//             const updateCandle = (candle, val) => ({
//                 time: candle.time,
//                 close: val,
//                 open: candle.open,
//                 low: Math.min(candle.low, val),
//                 high: Math.max(candle.high, val),
//             });
//
//             randomFactor = 25 + Math.random() * 25;
//             const date = new Date(Date.now());
//             const numberOfPoints = numberOfCandles * updatesPerCandle;
//             const initialData = [];
//             const realtimeUpdates = [];
//             let lastCandle;
//             let previousValue = samplePoint(-1);
//             for (let i = 0; i < numberOfPoints; ++i) {
//                 if (i % updatesPerCandle === 0) {
//                     date.setUTCSeconds(date.getUTCSeconds() + 1);
//                 }
//                 const time = (date.getTime() / 1000) + 7 * 3600;
//                 let value = samplePoint(i);
//                 const diff = (value - previousValue) * Math.random();
//                 value = previousValue + diff;
//                 previousValue = value;
//                 if (i % updatesPerCandle === 0) {
//                     const candle = createCandle(value, time);
//                     lastCandle = candle;
//                     if (i >= startAt) {
//                         realtimeUpdates.push(candle);
//                     }
//                 } else {
//                     const newCandle = updateCandle(lastCandle, value);
//                     lastCandle = newCandle;
//                     if (i >= startAt) {
//                         realtimeUpdates.push(newCandle);
//                     } else if ((i + 1) % updatesPerCandle === 0) {
//                         initialData.push(newCandle);
//                     }
//                 }
//             }
//
//             return {
//                 initialData,
//                 realtimeUpdates,
//             };
//         }
//
//         function generateTrendLine(data, offset = 100, shift = 0, baseShift = 0, volatilityFactor = 1) {
//             return data.map(candle => ({
//                 time: candle.time,
//                 value: candle.close + baseShift + shift + ((Math.random() - 0.5) * offset * volatilityFactor), // Tạo biến động với giá trị khởi điểm khác nhau
//             }));
//         }
//
//
//
//         const chartOptions = {
//             layout: {
//                 textColor: '#707070',
//                 background: { type: 'solid', color: 'transparent' },
//             },
//             height: 300,
//             timeScale: {
//                 timeVisible: true,
//                 secondsVisible: true,
//             },
//             grid: {
//                 vertLines: {
//                     visible: false,
//                 },
//                 horzLines: {
//                     visible: false,
//                 },
//             },
//         };
//
//         const container = chartContainerRef.current;
//         const chart = createChart(container, chartOptions);
//         const series = chart.addCandlestickSeries({
//             upColor: '#26a69a',
//             downColor: '#ef5350',
//             borderVisible: false,
//             wickUpColor: '#26a69a',
//             wickDownColor: '#ef5350',
//         });
//
//         const data = generateData(2500, 20, 1000);
//         series.setData(data.initialData);
//
//         // Thêm các đường xu hướng mua và bán
//         const buyTrendSeries = chart.addLineSeries({
//             color: '#2177ff',
//             lineWidth: 2,
//         });
//         const sellTrendSeries = chart.addLineSeries({
//             color: '#da2965',
//             lineWidth: 2,
//         });
//
//         const buyTrendData = generateTrendLine(data.initialData, 1000, 500, 1, 1.2); // Đường mua có biến động lớn hơn và dịch chuyển lên trên
//         const sellTrendData = generateTrendLine(data.initialData, -1500, -100, -1, 0.8); // Đường bán có biến động nhỏ hơn và dịch chuyển xuống dưới
//
//         buyTrendSeries.setData(buyTrendData);
//         sellTrendSeries.setData(sellTrendData);
//
//         chart.timeScale().fitContent();
//         chart.timeScale().scrollToPosition(5);
//
//         function* getNextRealtimeUpdate(realtimeData) {
//             for (const dataPoint of realtimeData) {
//                 yield dataPoint;
//             }
//             return null;
//         }
//         const streamingDataProvider = getNextRealtimeUpdate(data.realtimeUpdates);
//
//         const intervalID = setInterval(() => {
//             const update = streamingDataProvider.next();
//             if (update.done) {
//                 clearInterval(intervalID);
//                 return;
//             }
//             series.update(update.value);
//
//             // Cập nhật xu hướng mua và bán với biến động lớn hơn
//             const lastBuyTrend = buyTrendData[buyTrendData.length - 1].value;
//             const lastSellTrend = sellTrendData[sellTrendData.length - 1].value;
//             let randomFactorBuy = 100 + Math.random() * 30;
//             let randomFactorSell = - (500 + Math.random() * 30);
//             // Tạo sự biến động độc lập thay vì chỉ phụ thuộc vào giá trị đóng của phiên trước đó
//             // Tạo biến động cho 2 đường với hệ số khác nhau
//             const newBuyTrend = {
//                 time: update.value.time,
//                 value: lastBuyTrend + Math.sin(Math.random() * Math.PI) * 150 + (Math.random() - 0.5) * 80, // Biến động ngẫu nhiên cho xu hướng mua
//             };
//
//             const newSellTrend = {
//                 time: update.value.time,
//                 value: lastSellTrend + Math.cos(Math.random() * Math.PI) * 200 + (Math.random() - 0.5) * 120, // Biến động ngẫu nhiên cho xu hướng bán
//             };
//
//             // Xác suất để hai đường giao nhau
//             const shouldIntersect = Math.random() < 0.2; // 5% cơ hội hai đường giao nhau
//
//             if (shouldIntersect) {
//                 // Cho hai đường giao nhau bằng cách đặt giá trị của chúng gần nhau
//                 newSellTrend.value = newBuyTrend.value + (Math.random() - 0.5) * 50; // Giao nhau với một khoảng cách nhỏ
//             } else {
//                 // Đảm bảo khoảng cách thông thường là 900 đơn vị khi không giao nhau
//                 if (Math.abs(newBuyTrend.value - newSellTrend.value) < 900) {
//                     newSellTrend.value = newBuyTrend.value - 500 + (Math.random() - 0.5) * 150; // Điều chỉnh khoảng cách nếu cần
//                 }
//             }
//
//             buyTrendSeries.update(newBuyTrend);
//             sellTrendSeries.update(newSellTrend);
//         }, 500);
//
//     }, []);
//
//     return (
//         <div
//             ref={chartContainerRef}
//             style={{
//                 position: 'relative',
//                 width: '100%',
//                 height: '300px',
//                 background: `url('https://jpx-in.trade/img/bg-trade.053a293d.png') no-repeat center center`,
//                 backgroundSize: 'cover'
//             }}
//         >
//             <div className='box-titel-home flex-class justify-content-start'>
//                 <div className='flex-class justify-content-start'>
//                     <h5 className='font-weight-custom-bold fs-6 ms-3'>BTC/USDT</h5>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ChartComponent;

const TradingViewWidget = ({ symbol }) => {
    // Custom hook để tải script TradingView
    const useScript = (url) => {
        useEffect(() => {
            const script = document.createElement('script');
            script.src = url;
            script.async = true;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }, [url]);
    };

    useEffect(() => {
        const loadTradingViewWidget = () => {
            if (window.TradingView && window.TradingView.widget) {
                new window.TradingView.widget({
                    "autosize": true,
                    "symbol": "BITSTAMP:BTCUSD",
                    "interval": "1",
                    "timezone": "Asia/Ho_Chi_Minh",
                    "theme": "dark",
                    "style": "1",
                    "locale": "vi_VN",
                    "enable_publishing": false,
                    "hide_legend": true,
                    "allow_symbol_change": false,
                    "save_image": false,
                    "withdateranges": false,
                    "calendar": true,
                    "studies": [
                        "STD;MACD",
                        "STD;WMA"
                    ],
                    //set time range in 5 minutes
                    "time_frames": [
                        { text: "1m", resolution: "1" },
                    ],
                    container_id: "tradingview_2d7e4",
                    "support_host": "https://www.tradingview.com"
                });
            } else {
                console.error("TradingView library not loaded.");
            }
        };

        // Hàm kiểm tra script đã tải
        const checkScriptLoaded = () => {
            if (window.TradingView && window.TradingView.widget) {
                loadTradingViewWidget();
            } else {
                setTimeout(checkScriptLoaded, 100); // Kiểm tra lại sau 100ms nếu chưa tải
            }
        };

        checkScriptLoaded(); // Gọi hàm kiểm tra script đã tải

    }, [symbol]);

    // Tải script TradingView khi component được mount
    useScript('https://s3.tradingview.com/tv.js');

    return (
        <div className="tradingview-widget-container">
            <div id="tradingview_2d7e4"></div>
            <div className="tradingview-widget-copyright">
                <a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noopener" target="_blank">
                    TradingView
                </a>
            </div>
        </div>
    );
};

export default TradingViewWidget;

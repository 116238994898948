import Cookies from 'js-cookie';
const initialState = {
  modelUser: false,
  modalPassword: false,
  modalRegister: false,
  isCallApi: false,
  modalLogin: false,
  modalChangeUser: false,
  modalPay: false,
  slidebarStatus: false,
  codeStatus: false,
  listOrderMoney: null,
  isLogin: Cookies.get('token') && Cookies.get('token') !== null ? true : false,
  amount: 100,
  user: null,
  balanceUser: null,
  percentProfit: 0,
  rate: 0,
  charge_description: null,
  send_money: null,
  statePayModal: false,
  loaddingHistiry : false,
  orderTransactions: {
    win: 0,
    lose: 0,
    allMoneyTransaction: 0,
    allMoneyRevenue: 0,
    coutnBuy: 0,
    coutnSell: 0,
  }
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMODALUSER':
      return {
        ...state,
        modelUser: action.payload,
      };
    case 'SETMODALPASSWORD':
      return {
        ...state,
        modalPassword: action.payload,
      };
    case 'SETMODALPAY':
      return {
        ...state,
        modalPay: action.payload,
      };
    case 'SETSLIDERBAR':
      return {
        ...state,
        slidebarStatus: action.payload,
      };
    case 'SETCODESTATUS':
      return {
        ...state,
        codeStatus: action.payload,
      };
    case 'SETLISSTORDERMONEY':
      return {
        ...state,
        listOrderMoney: action.payload,
      };
    case 'SETISLOGIN':
      return {
        ...state,
        isLogin: action.payload,
      };
    case 'SETAMOUNT':
      return {
        ...state,
        amount: action.payload,
      };
    case 'SETUSERINFOR':
      return {
        ...state,
        user: action.payload,
      };
    case 'SETBALANCEUSER':
      return {
        ...state,
        balanceUser: action.payload,
      };
    case 'SETPERCENTPROFIT':
      return {
        ...state,
        percentProfit: action.payload,
      };
    case 'SETRATE':
      return {
        ...state,
        rate: action.payload,
      };
    case 'SETCHARGEDESCIPTION':
      return {
        ...state,
        charge_description: action.payload,
      };
    case 'SETSENDMONEY':
      return {
        ...state,
        send_money: action.payload,
      };
    case 'SETSTATEPAYMODAL':
      return {
        ...state,
        statePayModal: action.payload,
      };
    case 'SETSORDERTRANSACTION':
      return {
        ...state,
        orderTransactions: action.payload,
      };
    case 'SETMODALCHANGEUSER':
      return {
        ...state,
        modalChangeUser: action.payload,
      };
    case 'SETMODALREGISTER':
      return {
        ...state,
        modalRegister: action.payload,
      };
    case 'SETMODALLOGIN':
      return {
        ...state,
        modalLogin: action.payload,
      };
    case 'SETISCALLAPI':
      return {
        ...state,
        isCallApi: action.payload,
      };
      case 'SETLOADINGHISTORY':
      return {
        ...state,
        loaddingHistiry: action.payload,
      };
      
    default:
      return state;
  }
};

export default Reducer;
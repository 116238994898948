export const setModalUser = (data) => ({
    type: 'SETMODALUSER',
    payload: data,
});
export const setModalPassword = (data) => ({
    type: 'SETMODALPASSWORD',
    payload: data,
});
export const setSliderBar = (data) => ({
    type: 'SETSLIDERBAR',
    payload: data,
});
export const setModalNotifation = (data) => ({
    type: 'SETCODESTATUS',
    payload: data,
});
export const setListMoney = (data) => ({
    type: 'SETLISSTORDERMONEY',
    payload: data,
});
export const setIsLogin = (data) => ({
    type: 'SETISLOGIN',
    payload: data,
});
export const setAmount = (data) => ({
    type: 'SETAMOUNT',
    payload: data,
});
export const setUser = (data) => ({
    type: 'SETUSERINFOR',
    payload: data,
});
export const setBalanceUser = (data) => ({
    type: 'SETBALANCEUSER',
    payload: data,
});
export const setPercentProfit = (data) => ({
    type: 'SETPERCENTPROFIT',
    payload: data,
});

export const setModalPay = (data) => ({
    type: 'SETMODALPAY',
    payload: data,
});
export const setRate = (data) => ({
    type: 'SETRATE',
    payload: data,
});

export const setChargeDescription = (data) => ({
    type: 'SETCHARGEDESCIPTION',
    payload: data,
});

export const setStatePayModal = (data) => ({
    type: 'SETSTATEPAYMODAL',
    payload: data,
});
export const setOrderTransaction = (data) => ({
    type: 'SETSORDERTRANSACTION',
    payload: data,
});

export const setChangeUser= (data) => ({
    type: 'SETSORDERTRANSACTION',
    payload: data,
});

export const setModalChangeUser= (data) => ({
    type: 'SETMODALCHANGEUSER',
    payload: data,
});

export const setModalRegister= (data) => ({
    type: 'SETMODALREGISTER',
    payload: data,
});
export const setModalLogin= (data) => ({
    type: 'SETMODALLOGIN',
    payload: data,
});
export const setSendMoney =(data) => ({
    type: 'SETSENDMONEY',
    payload: data,
});
export const setIsCallAPi =(data) => ({
    type: 'SETISCALLAPI',
    payload: data,
});
export const setLoadingHistory =(data) => ({
    type: 'SETLOADINGHISTORY',
    payload: data,
});


import React from 'react'
import SliderLogin from './component/slider';
import Content from './component/content';
import About from './component/about';
import Sesstion2 from './component/sesstion2';
import Trade from './component/Trade';
import Session3 from './component/Session3';
function MainLogin() {
  return (
    <div className=''>
        <SliderLogin/>
        <iframe className='w-100 iframe-home' src='https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,14,20&border=show&speed=50&click_target=blank&theme=dark&by-cr=28A745&sl-cr=DC3545&flags=circle&d_mode=regular&column=ask,bid,spread&lang=en&font=Arial,%20sans-serif'/>
        <Content/>
        <About/>
        <Sesstion2/>
        <Trade/>
        <Session3/>
    </div>
  )
}

export default MainLogin
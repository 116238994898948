import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import APILink from '../../service/APILink';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {setRate, setChargeDescription, setModalPay, setSendMoney} from "../../redux/accction/acction";
import RechargeModalComponent from './rechargeModalComponent';
import WithdrawModalComponent from './withdrawModalComponent';
const PayModal = () => {
  const dispatch = useDispatch();
  const modalShow = useSelector((state) => state.reducers.modalPay);
  const statePayModal = useSelector((state) => state.reducers.statePayModal);


  return (
    <>
          <Modal
            show={modalShow}
            onHide={() => dispatch(setModalPay(false))}
            // size="xl"
            // aria-labelledby="contained-modal-title-vcenter"
            className='pay-modal'
            centered
          >
              <div className='icon-close-pay-modal flex-class justify-content-center'  onClick={() => dispatch(setModalPay(false))} >
              <i className="fa-solid fa-xmark"></i>
          </div>
        <Modal.Body>
          <Tabs
            defaultActiveKey={statePayModal === false ? "recharge" : "withdrawMoney"} 
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="recharge" title="Nạp tiền" className='mt-5'>
              <RechargeModalComponent/>
            </Tab>
            <Tab eventKey="withdrawMoney" title="Rút tiền" className='mt-5'>
              <WithdrawModalComponent/>
            </Tab>
          </Tabs>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default PayModal;

import React from 'react'
import TablePanelButton from '../component/main/tablePanelButton';
import Result from '../component/main/result';
import ChartComponent from '../component/main/chartHome';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import GaugeComponent from '../component/main/Gauge';
function Home() {

    return (
        <div className='row'>
            <div className='col-xl-9 col-lg-9 col-md-12 col-sm-12'>
                <iframe className='w-100 iframe-home' src='https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,14,20&border=show&speed=50&click_target=blank&theme=dark&by-cr=28A745&sl-cr=DC3545&flags=circle&d_mode=regular&column=ask,bid,spread&lang=en&font=Arial,%20sans-serif'/>
                <ChartComponent/>
                <div className='tab-content mt-5 container'>
                <Tabs
                    defaultActiveKey="indicators"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="indicators" title="INDICATORS" className='mt-5'>
                          <div className='row'>
                            <div className='col-4'>
                                 <GaugeComponent title={'Oscillators'}/>
                            </div>
                            <div className='col-4'>
                                 <GaugeComponent title={'Summary'}/>
                            </div>
                            <div className='col-4'>
                                 <GaugeComponent title={'Averages'}/>
                            </div>
                          </div>
                    </Tab>
                    {/* <Tab eventKey="lastresults" title="LAST RESULTS" className='mt-5'>
                     
                    </Tab> */}
                </Tabs>
            </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 bg-primary-custom p-3 mt-3 mt-lg-0'>
              <TablePanelButton/>
              <Result/>
            </div>
        </div>
    )
}
export default Home
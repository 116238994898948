import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import APILink from '../../service/APILink';
import {setBalanceUser } from "../../redux/accction/acction";
function Result() {
    const dispatch = useDispatch();
    const [seconds, setSeconds] = useState(60);
    const [isActive, setIsActive] = useState(false);
    const percentProfit = useSelector((state) => state.reducers.percentProfit);
    const [progress, setProgress] = useState(40);
    const [load, setLoad] = useState(false);
    const amount = useSelector((state) => state.reducers.amount);
    useEffect(() => {
        let timer;
        if (isActive) {
            timer = setInterval(() => {
                setSeconds((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setIsActive(false);
                        return 60;
                    }   
                    return prev - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [isActive]);
    useEffect(() => {
      const interval = setInterval(() => {
          const newPercentage = Math.floor(Math.random() * (60 - 40 + 1)) + 40;
          setProgress(newPercentage);
      }, 2000);
      return () => clearInterval(interval);
  }, []);
  const postAPi = async (is_high) => {
    setLoad(true);
    if (seconds < 60) {
        toast.error('Vui lòng chờ khi hết thời gian');
        setLoad(false);
        return; 
    } else if (amount < 5) {
        toast.error('Số tiền không thể nhỏ hơn 5');
        setLoad(false);
        return;
    }

    const data = {
        amount: amount, 
        is_high: is_high
    };

    await APILink.post('trading', data)
        .then(async (response) => {
            if (response.data.status === "success") {
                toast.success(response.data.message);
                startCountdown();
                await new Promise(resolve => setTimeout(resolve, 60000));
                await getResult(response.data.id);
            } else {
                toast.error(response.data.message);
            }
        })
        .catch((error) => {
            console.error(error);
            toast.error('Đã xảy ra lỗi. Vui lòng thử lại.');
        })
        .finally(() => {
            setLoad(false); 
        });
};

    const getResult =(id) =>{
      if(id !== null){
        const data = {
            id : id
        }
        APILink.post('checkStatus', data)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            dispatch(setBalanceUser(response.data.balance))
          }else{
            toast.error(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }
    }
    const startCountdown = () => {
        setSeconds(60);
        setIsActive(true);
    };
  return (
      <div className='mt-4 mb-4 row'>
          <div className="d-flex flex-row flex-lg-column justify-content-center align-items-center gap-3">
              <span className='text-center mb-lg-3 fs-5'>Lợi nhuận</span>
              <p className='text-center color-green'>{percentProfit} % <span
                  className='fs-4 ms-1'>+{amount}</span></p>
          </div>
          <div className='progress-indicator d-none d-lg-block'>
              <p className='text-center mt-3 mb-1 fs-7'>
                  Chỉ báo tâm lý
              </p>
              <div className='mt-1 mb-4'>
                  <ProgressBar className="custom-progress-bar" variant="success" now={progress}/>
                  <div className='flex-class justify-content-between mt-2'>
                      <p className='color-green'>{progress}%</p>
                      <p className='color-red'>{100 - progress}%</p>
                  </div>
              </div>
          </div>
          <div className='mt-2 mb-2 d-none d-lg-block'>
              <button className='flex-class justify-content-center btn-acction-result btn-loadding bg-gray'>
                  {isActive ? `Chờ kết quả ${seconds}s` : 'Chờ kết quả 60s'}
              </button>
          </div>
          <div className='mt-2 mb-2 col-5 col-lg-12'>
              <button disabled={load} className='flex-class justify-content-center btn-acction-result bg-green'
                      onClick={() => postAPi(1)}>
                  <p>Mua</p> <i class="fa-solid fa-chart-line ms-1"></i>
              </button>
          </div>
          <div className='mt-2 mb-2 col-2 d-block d-lg-none'>
              <button
                  className='flex-class justify-content-center btn-acction-result btn-loadding bg-gray boder-circle'>
                  {isActive ? `${seconds}s` : '60s'}
              </button>
          </div>
          <div className='mt-2 mb-2 col-5 col-lg-12'>
              <button disabled={load} className='flex-class justify-content-center btn-acction-result bg-red'
                      onClick={() => postAPi(0)}>
                  <p>Bán</p>
                  <svg width="20px" height="20px" className='ms-1' viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5 5C5 4.44772 4.55228 4 4 4C3.44772 4 3 4.44772 3 5V6.99983C3 6.99994 3 7.00006 3 7.00017L3 19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H5V9.41421L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13 9.41421L17.5858 14H15C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16H20C20.5523 16 21 15.5523 21 15V10C21 9.44772 20.5523 9 20 9C19.4477 9 19 9.44772 19 10V12.5858L13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L5 6.58579V5Z"
                                fill="#ffffff"></path>
                      </g>
                  </svg>
              </button>
          </div>
      </div>
  )
}

export default Result;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './home';
import Pay from './pay';
import DashboardSetting from "./dashboardSetting";
import Login from "./login";
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Excavator from './excavator';
import Chat from './chat';
function Main() {
  return (
    <Routes>
      <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
      <Route path="/user/balance" element={<ProtectedRoute element={<Pay />} />} />
      <Route path="/user/trade/history" element={<ProtectedRoute element={<DashboardSetting />} />} />
      <Route path="/excavator" element={<ProtectedRoute element={<Excavator />} />} />
      <Route path="/chat" element={<ProtectedRoute element={<Chat />} />} />
      <Route path="/" element={<ProtectedRoute element={<Login />} redirectPath="/home" />} />

    </Routes>
  )
}

export default Main;

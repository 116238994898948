import { useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';
import APILink from "../../service/APILink";
import { toast } from 'react-toastify';
import { setModalPay, setIsCallAPi } from "../../redux/accction/acction";
import {formatPrice} from "../../service/funWeb"

function WithdrawModalComponent() {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [money, setMoney] = useState(null);
    const [bank_number, setBank_number] = useState('');
    const [bank_name, setBank_name] = useState('');
    const [load, setLoad] = useState(false);
    const balanceUser = useSelector((state) => state.reducers.balanceUser);
    const user = useSelector((state) => state.reducers.user);
    const rate = useSelector((state) => state.reducers.rate);
    const [isVerified, setIsVerified] = useState(false);
   
    const setValueMoney = (moneyValue) => {
        setMoney(moneyValue)
    };
    
    useEffect(()=>{
        if(user !== null){
            setBank_number(user.bank_number);
            setBank_name(user.bank_name);
            if(user.bank_name && user.bank_number){
                setIsVerified(true);
            }
        }
    },[])

    const postWithdraw = () => {
        if (Number.isInteger(Number(money)) && Number(money) >= 20) {
            const data ={
                amount : Number(money),
                bank_name : bank_name ,
                bank_number : bank_number,
            }
            setLoad(true)
            APILink.post('withdraw', data)
              .then((response) => {
                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    setMoney(null);
                    dispatch(setModalPay(false));
                    window.location.reload();
                }else{
                    toast.error(response.data.message);
                }
              })
              .catch((error) => {
                console.error(error);
              }).finally(()=>{
                setLoad(false)
                dispatch(setIsCallAPi(true))
              });
             
        }else{
            toast.error('Vui lòng nhập số tiền cần rút lớn hơn 20');
        }
      }
  
    return (
        <div>
            <Row className="mb-3 ">
                <div className='color-white'>
                    <p>UDS trong ví : ${balanceUser}<br/>
                       Số tiền rút quy đổi: {formatPrice(money*rate)}</p>
                </div>
                <Form.Group as={Col} md="12" className='mt-3'>
                    <Form.Label>Số tiền cần rút</Form.Label>
                    <Form.Control
                        required
                        type="numbers"
                        value={money}
                        max={1000000}
                        min={20}
                        placeholder="Tối thiểu 20 USD"
                        className='bg-transparent color-white placeholder-white boder-none'
                        onChange={(e)=>setValueMoney(e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Col} md="12" className='mt-3'>
                    <Form.Label>Ngân hàng</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={bank_name}
                        className='bg-transparent color-white placeholder-white border-none'
                        onChange={(e) => setBank_name(e.target.value)}
                        disabled = {isVerified}
                    />

                </Form.Group>
                <Form.Group as={Col} md="12" className='mt-3'>
                    <Form.Label>Số tài khoản</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={bank_number }
                        className='bg-transparent color-white placeholder-white boder-none'
                        onChange={(e)=>setBank_number(e.target.value)}
                        disabled = {isVerified}
                    />
                </Form.Group>
                
                <Form.Group as={Col} md="12" className='mt-3'>
                    <Form.Label>Chủ tài khoản (Cần xác minh hồ sơ)</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={user && user !== null && user.last_name!== null  && user.first_name !== null ? user.last_name + " " +user.first_name: null }
                        className='bg-transparent color-white placeholder-white boder-none'
                        disabled
                    />
                </Form.Group>
                {/* <Form.Group as={Col} md="12" className='mt-3'>
                    <Form.Label>Ghi chú</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        className='bg-transparent color-white placeholder-white boder-none'
                    />
                </Form.Group> */}
            </Row>
           <div className='flex-class justify-content-center w-100 mt-3 mb-2'>
                <Button type="submit" className="btn-withdraw-modal" onClick={postWithdraw} disabled={load}>  {load === true ? 'Đang xử lý...' : 'Rút tiền'}</Button>
           </div>
        </div>
    )
}

export default WithdrawModalComponent
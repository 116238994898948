import React, { useEffect } from 'react';

function Trade() {
    useEffect(() => {
        // Create and append the TradingView script
        const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
            width: "100%",
            height: "350",
            defaultColumn: "overview",
            defaultScreen: "general",
            market: "forex",
            showToolbar: true,
            colorTheme: "light",
            locale: "en"
        });

        const container = document.getElementById('tradingview-widget');
        if (container) {
            container.innerHTML = '';
            container.appendChild(script);
        }
        return () => {
            if (container) {
                container.innerHTML = '';
            }
        };
    }, []);
    return (
        <div className='w-100 p-4 row'>
            <h4 className='fs-3 text-center mt-5 mb-5'>
                Giao dịch tài sản từ thị trường toàn cầu
            </h4>
            <div className='col-12'>
                <iframe
                    scrolling="no"
                    allowTransparency="true"
                    className='w-100'
                    frameBorder="0"
                    src="https://www.tradingview-widget.com/embed-widget/tickers/?locale=vi_VN#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22US%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%20to%20USD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22Ethereum%22%7D%5D%2C%22isTransparent%22%3Afalse%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A72%2C%22utm_source%22%3A%22jpx.lseview.asia%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22tickers%22%2C%22page-uri%22%3A%22jpx.lseview.asia%2Fhome%22%7D"
                    title="tickers TradingView widget"
                    lang="en"
                    style={{ height: "70px" }}
                ></iframe>
            </div>
            <div className='col-12 col-lg-6 d-flex align-items-stretch'>
                <div id="tradingview-widget" style={{ height: "350px", width: "100%" }}></div>
            </div>
            <div className='col-12 col-lg-6 flex-class justify-content-center d-flex align-items-stretch'>
                    <div className='login-menu w-80 p-0' >
                    <h4 className='fs-3 text-center mt-5 mb-5'>
              Tin tức và sự kiện
            </h4>
                        <li className='p-3'>
                            <a className='fs-6' href='https://vietstock.vn/2012/03/niem-yet-tren-so-gdck-tokyo-thoi-co-cho-doanh-nghiep-viet-741-216739.htm' >
                                08/03/2023 15:33<br />
                                Niêm yết trên sở GDCK Tokyo: Thời cơ cho doanh nghiệp Việt
                            </a>
                        </li>
                        <li className='p-3'>
                            <a className='fs-6' href='https://vietstock.vn/2024/08/vietcombank-vietinbank-bat-ngo-dieu-chinh-ngay-giao-vang-mieng-sjc-759-1217654.htm' >
                                08/03/2024 15:33<br />
                                Vietcombank, VietinBank bất ngờ điều chỉnh ngày giao vàng miếng SJC
                            </a>
                        </li>
                        <li className='p-3'>
                            <a className='fs-6' href='https://vietstock.vn/2024/08/hsbc-viet-nam-van-la-diem-den-ua-chuong-cho-dong-von-fdi-768-1217620.htm' >
                                08/03/2024 15:33<br />
                                HSBC: Việt Nam vẫn là điểm đến ưa chuộng cho dòng vốn FDI
                            </a>
                        </li>
                    </div>
            </div>
        </div>
    );
}

export default Trade;

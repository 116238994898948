import React,{useState, useEffect} from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import  DashboardSettingTable  from "../component/main/dashboardSettingTable";
import { useSelector, useDispatch } from 'react-redux';
import APILink from '../service/APILink';
import {setOrderTransaction} from "../redux/accction/acction"
import Cookies from 'js-cookie';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d';

Highcharts3D(Highcharts);

function DashboardSetting() {
    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);
    const [dataList, setData] = useState(null)
    const [startDate, setStartDate] = useState(new Date());
    const token = Cookies.get('token');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [nextPage, setNextPage] = useState(null);
    const [listdata, setListdata] = useState(null);
   const [typeLable, setTypeLable] = useState(['Thời gian', 'Mã giao dịch' , 'Số tiền', 'Trạng thái']);
  
   const orderTransactions = useSelector((state) => state.reducers.orderTransactions);
   const [buy, setBuy] = useState(0);
    // const data = {
    //     labels: [
    //         'Tỉ lệ thắng',
    //         'Tỉ lệ thua'
    //     ],
    //     datasets: [{
    //         label: 'My First Dataset',
    //         data: [orderTransactions.lose, orderTransactions.win !== 0 ? orderTransactions.win  : 100],
    //         backgroundColor: [
    //             'rgb(234, 84, 85)',
    //             'rgb(40, 199, 111)'
    //         ],
    //         hoverOffset: 4
    //     }]
    // };

    // const options = {
    //     plugins: {
    //         legend: {
    //             position: 'top',
    //         },
    //         tooltip: {
    //             callbacks: {
    //                 label: function (tooltipItem) {
    //                     return `${tooltipItem.label}: ${tooltipItem.raw}`;
    //                 }
    //             }
    //         }
    //     },
    //     responsive: true,
    // };
    const options = {
        chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0,
            },
            backgroundColor: null,
          },
        title: {
          text: 'Số liệu B.Os',
        },
        plotOptions: {
          pie: {
            innerSize: 50,
            depth: 45,
            colors: ['rgb(234, 84, 85)', 'rgb(40, 199, 111)']
          },
        },
        series: [
          {
            name: 'Số lần',
            data: [
              ['Thua', orderTransactions.lose],
              ['Thắng',  orderTransactions.win !== 0 ? orderTransactions.win  : 100 ]
            ],
          },
        ],
      };
    useEffect(()=>{
       
        if(token && token !== null){
            getListHistoryTransactionFun();
        }      
    },[limit, token]);

    useEffect(() => {
        const coutnBuy = orderTransactions.coutnBuy ?? 0;
        const coutnSell = orderTransactions.coutnSell ?? 0;
        if (typeof coutnBuy === 'number' && typeof coutnSell === 'number' && (coutnBuy + coutnSell) > 0) {
            const percentage = (coutnBuy / (coutnBuy + coutnSell) * 100).toFixed(2);
            setBuy(percentage);
        } else {
            setBuy('0.00'); 
        }
    }, [orderTransactions.coutnBuy, orderTransactions.coutnSell]);
    

    const getListHistoryTransactionFun = ()=>{
       
        APILink.get(`order-transactions?page=1&limit=${limit}`)
        .then((response) => {
          if (response.data.status === "success") {
           setListdata(response.data.result.data.data);
           setNextPage(response.data.result.data.next_page_url);
           dispatch(setOrderTransaction(
            {
                win: response.data.result.inforUser.countWin ,
                lose: response.data.result.inforUser.countLose,
                allMoneyTransaction: response.data.result.inforUser.allMoneyTransaction,
                allMoneyRevenue: response.data.result.inforUser.allMoneyRevenue,
                coutnBuy: response.data.result.inforUser.coutnBuy,
                coutnSell: response.data.result.inforUser.coutnSell
            }
           ));
      
           ///ssetBuy(orderTransactions.coutnBuy / (orderTransactions.coutnBuy + orderTransactions.coutnSell) * 100).toFixed(2)
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const nextPageFun = () =>{
        loadMoreAcctionFun(page+1, limit)
    }
    const loadMoreAcctionFun = (page, limit)=>{
        setLoad(true)
        APILink.get(`order-transactions?page=${page}&limit=${limit}`)
        .then((response) => {
          if (response.data.status === "success") {
            setListdata(prevListData => [
                ...prevListData, 
                ...response.data.result.data .data
            ]);
          setNextPage(response.data.result.data.next_page_url);
            setPage(response.data.result.data.current_page);
            setLoad(false)
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const ac = (orderTransactions.win / (orderTransactions.win + orderTransactions.lose) * 100).toFixed(2);
    return (
        <div className='row p-3 p-lg-5'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 row data-board'>
                <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
                    <h4>Số liệu B.O</h4>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
                <div className='col-xl-4 col-lg-12 col-md-12 col-sm-12 flex-class justify-content-center justify-content-lg-end'>
                    <div>
                        <div className='mt-3 mb-3 text-center'>
                            <p>Tổng vòng</p>
                            <p>{orderTransactions.win + orderTransactions.lose}</p>
                        </div>
                        <hr />
                        <div className='mt-3 mb-3 text-center'>
                            <p>Tỷ lệ thắng</p>
                            <p>{ Number.isNaN(ac)  ? 0  : ac}%</p>
                            </div>
                        <hr />
                        <div className='mt-3 mb-3 text-center'>
                            <p>Tổng giao dịch</p>
                            <p>$ {orderTransactions.allMoneyTransaction}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-lg-6 mt-0  row p-0 ps-lg-5'>
                {/* <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 flex-class justify-content-end mt-3 mb-3'>
                    <div className='flex-class justify-content-center bg-brown net-profit'>
                        <div className=''>
                            <i class="fa-solid fa-chart-simple font-icon-setting color-violet"></i>
                        </div>
                        <div className='ms-3'>
                            <p className='fs-4 mb-1'>Lợi nhuận ròng</p>
                            <span className='fs-4'>$0.00</span>
                        </div>
                    </div>
                </div> */}
                <div className='col-12 col-lg-12 flex-class justify-content-center justify-content-lg-end mt-3 mb-3 mt-lg-0 mb-lg-0'>
                    <div className='flex-class justify-content-center bg-blandGreen net-profit'>
                        <div className=''>
                            <i class="fa-solid fa-money-bill font-icon-setting"></i>
                        </div>
                        <div className='ms-3'>
                            <p className='fs-4 mb-1'>Tổng doanh thu</p>
                            <span className='fs-4'> <p>$ {orderTransactions.allMoneyRevenue}</p></span>
                        </div>
                    </div>
                </div>
                <div className='col-12 mb-3 mt-4 mt-lg-6'>
                    <ProgressBar className="custom-progress-bar" variant="success" now={buy} />
                    <div className='flex-class justify-content-between mt-2 '>
                        <p className='color-green'>Mua {buy} %</p>
                        <p className='color-red'>Bán {(100 -buy).toFixed(2)}</p>
                    </div>
                </div>
            </div>
            <div className='col-12 mt-3 mt-lg-5'>
                <div className='titel-transfer flex-class justify-content-between'>
                    <div className='titel-table'> 
                        <p>Lịch sử giao dịch</p>    
                     </div>
                    {/* <div className='d-block d-flex flex-class justify-content-end'>
                        <DatePicker className='time-input-picker bg-transparent ps-3' selected={startDate} onChange={(date) => setStartDate(date)} />
                        <DatePicker className='time-input-picker bg-transparent ms-4 ps-3'  selected={startDate} onChange={(date) => setStartDate(date)} />
                        <button className='ms-4 search-time-data  pe-3 ps-3 pt-2 pb-2 bg-blandGreen' >Tìm kiếm</button>
                    </div> */}

                </div>
                <div className='mt-3'>
                    <DashboardSettingTable titlerLable={typeLable} data={listdata} />
                    {nextPage && nextPage !== null ? 
                    ( <div className='flex-class justify-content-center w-100 mt-3 '>
                        <button className='btn-load-more' onClick={nextPageFun} disabled={load}>
                        {load === true ? 'Đang xử lý...' : 'Xem thêm'}
                        </button>
                    </div>) 
                    :  null
                }
                </div>
            </div>
        </div>
    );
}

export default DashboardSetting;

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ element, redirectPath }) => {
  const [loading, setLoading] = useState(true);
  const token = Cookies.get('token');
  const [tokenFromUrl, setTokenFromUrl] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenUrl = urlParams.get('token');

    if (tokenUrl) {
      Cookies.set('token', tokenUrl, { expires: 30 });
      setTokenFromUrl(tokenUrl);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return null; // You might want to show a loading spinner or similar
  }

  if (redirectPath) {
    return token || tokenFromUrl ? <Navigate to={redirectPath} /> : element;
  }

  return token || tokenFromUrl ? element : <Navigate to="/" />;
};

export default ProtectedRoute;

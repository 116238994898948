import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ImageUploading from 'react-images-uploading';
import {setIsCallAPi, setModalChangeUser} from "../../redux/accction/acction";
import SVGFront from "../../asset/img/id_front.3287c145.svg";
import SVGBack from "../../asset/img/id_back.fa1d0d27.svg";
import * as formik from 'formik';
import * as yup from 'yup';
import APILink from '../../service/APILink';
import { toast } from 'react-toastify';

function ModadlChangeUser() {

    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);
    const [validated, setValidated] = useState(false);
    const user = useSelector((state) => state.reducers.user);
    const [step, setStep] = useState("1");
    const { Formik } = formik;
    const [imagesFront, setImagesFront] = useState([]);
    const [imagesBack, setImageBack] = useState([]);
    const initialValues = {
        firstName: user?.first_name || '',
        lastName: user?.last_name || '',
        identification: user?.identification || '',
        bank_name: user?.bank_name || '',
        bank_number: user?.bank_number || '',
        imagesBackLink: user?.back_img || '',
        imagesFrontLink: user?.front_img || ''
    };
   
    const maxNumber = 1;

    const onChangeFront = (imagesFront) => {

        setImagesFront(imagesFront.slice(0, 1));
    };

    const onChangeBack = (imagesBack) => {
        setImageBack(imagesBack.slice(0, 1));
    };


    const schema = yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        username: yup.string().required(),
        bank_name: yup.string().required(),
        bank_number: yup.string().required(),
    });

    const uploadUserInfor = (values) => {
        const formData = new FormData();
        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('bank_number', values.bank_number);
        formData.append('bank_name', values.bank_name);
        formData.append('identification', values.identification);
        if (imagesFront.length > 0) {
            formData.append('frontImg', imagesFront[0].file);
        }
        if (imagesBack.length > 0) {
            formData.append('backImg', imagesBack[0].file);
        }

        VerificationInfor(formData);
    };

    const VerificationInfor = (data) => {
        setLoad(true)
        APILink.post('veryfication-user', data)
            .then((response) => {
                if (response.data.status === "success") {
                    toast.success(response.data.message)
                    dispatch(setModalChangeUser(false))
                }
            })
            .catch((error) => {
                console.error(error);
            });
            dispatch(setIsCallAPi(true));
            setLoad(false)
    }
    return (
        <div className='modal-change-user'>
            <div className='modal-change-user-modal'>
                <div className='modal-content'>
                    <div className='modal-container p-4'>
                        <div className='modal-header flex-class justify-content-between'>
                            <h3 className='text-uppercase font-weight-bold'>Cập nhật thông tin người dùng</h3>
                            <i onClick={() => dispatch(setModalChangeUser(false))} className="fa-solid fa-xmark"></i>
                        </div>
                        <div className='mt-5'>
                            <Formik
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <Form>
                                        <Tabs
                                            activeKey={step}
                                            id="uncontrolled-tab-example"
                                            onSelect={(key) => setStep(key)}
                                            className="mb-3 tab-user flex-class justify-content-between ps-4 pe-4"
                                        >
                                            <Tab eventKey="1" title="1" className='tab-item-user'>
                                                <>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} className='col-12 col-lg-6 mt-3 mt-lg-0'>
                                                            <Form.Label>Họ</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="lastName"
                                                                className='bg-transparent color-white placeholder-white'
                                                                value={initialValues.lastName !== "" ? initialValues.lastName
                                                                    : values.lastName}
                                                                onChange={handleChange}
                                                                isValid={touched.lastName && !errors.lastName}
                                                            />
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col} className='col-12 col-lg-6 mt-3 mt-lg-0'>
                                                            <Form.Label>Tên</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="firstName"
                                                                className='bg-transparent color-white placeholder-white'
                                                                value={initialValues.firstName !== "" ? initialValues.firstName
                                                                    : values.firstName}
                                                                onChange={handleChange}
                                                                isValid={touched.firstName && !errors.firstName}
                                                            />
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} className='col-12'>
                                                            <Form.Label>Số chứng minh</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="identification"
                                                                className='bg-transparent color-white placeholder-white'
                                                                value={initialValues.identification !== "" ? initialValues.identification :
                                                                    values.identification}
                                                                onChange={handleChange}
                                                                isValid={touched.identification && !errors.identification}
                                                            />
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} className='col-12'>
                                                            <Form.Label>Tài khoản ngân hàng</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="bank_number"
                                                                className='bg-transparent color-white placeholder-white'
                                                                value={initialValues.bank_number !== "" ? initialValues.bank_number :
                                                                    values.bank_number}
                                                                onChange={handleChange}
                                                                isValid={touched.bank_number && !errors.bank_number}
                                                            />
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} className='col-12'>
                                                            <Form.Label>Tên ngân hàng</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="bank_name"
                                                                className='bg-transparent color-white placeholder-white'
                                                                value={initialValues.bank_name !== "" ? initialValues.bank_name :
                                                                    values.bank_name}
                                                                onChange={handleChange}
                                                                isValid={touched.bank_name && !errors.bank_name}
                                                            />
                                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <div className='btn-step bg-transparent flex-class justify-content-center mt-3' onClick={() => setStep('2')}>Tiếp theo</div>
                                                </>
                                            </Tab>
                                            <Tab eventKey="2" title="2" className='tab-item-user'>
                                                <div>
                                                    <p>Xác thực danh tính<br />
                                                        1. Hãy đảm bảo rằng ảnh chụp toàn vẹn và rõ ràng, có định dạng JPG, PNG, JPEG. <br />
                                                        2. Hãy chụp ảnh Chứng minh thư/Hộ chiếu của bạn. Ảnh cần sáng và rõ ràng, không bị mất góc.
                                                    </p>
                                                </div>
                                                <div className='scroll-y'>
                                                    <div className='row mt-4'>
                                                        <label className='mb-3'>-Mặt trước Chứng minh thư/Hộ chiếu</label>
                                                        <div className='col-6'>
                                                            <ImageUploading
                                                                multiple={false}
                                                                value={imagesFront}
                                                                onChange={onChangeFront}
                                                                maxNumber={maxNumber}
                                                                dataURLKey="data_url"
                                                            >
                                                                {({
                                                                    imageList,
                                                                    onImageUpload,
                                                                    onImageRemoveAll,
                                                                    onImageUpdate,
                                                                    onImageRemove,
                                                                    isDragging,
                                                                    dragProps,
                                                                }) => (
                                                                    <div className="upload__image-wrapper">
                                                                        <div
                                                                            style={isDragging ? { color: 'red' } : undefined}
                                                                            onClick={onImageUpload}
                                                                            {...dragProps}
                                                                            className='btn-upload-img btn-front-img flex-class justify-content-center'
                                                                        >
                                                                            {imagesFront.length === 0 ?
                                                                                (
                                                                                    initialValues.imagesFrontLink !== "" ?
                                                                                        (<div className="image-item">
                                                                                            <img src={process.env.REACT_APP_SERVER_URL + initialValues.imagesFrontLink} alt="" width="100" />
                                                                                        </div>)
                                                                                        :
                                                                                        (<svg width={'64px'} height={'64px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M17 17H17.01M15.6 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H8.4M12 15V4M12 4L15 7M12 4L9 7" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                                                                        )
                                                                                )
                                                                                :
                                                                                imagesFront.length > 0 && (
                                                                                    <div className="image-item">
                                                                                        <img src={imagesFront[0].data_url} alt="" width="100" />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </ImageUploading>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='btn-upload-img flex-class justify-content-center'>
                                                                <div className="image-item ">
                                                                    <img src={SVGFront} alt="" width="100" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-4'>
                                                        <label className='mb-3'>-Mặt sau Chứng minh thư/Hộ chiếu</label>
                                                        <div className='col-6'>
                                                            <ImageUploading
                                                                multiple={false}
                                                                value={imagesBack || []}
                                                                onChange={onChangeBack}
                                                                maxNumber={maxNumber}
                                                                dataURLKey="data_url"
                                                            >
                                                                {({
                                                                    imageList,
                                                                    onImageUpload,
                                                                    onImageRemoveAll,
                                                                    onImageUpdate,
                                                                    onImageRemove,
                                                                    isDragging,
                                                                    dragProps,
                                                                }) => (
                                                                    <div className="upload__image-wrapper">
                                                                        <div
                                                                            style={isDragging ? { color: 'red' } : undefined}
                                                                            onClick={onImageUpload}
                                                                            {...dragProps}
                                                                            className='btn-upload-img btn-front-img flex-class justify-content-center'
                                                                        >
                                                                            {imagesBack.length === 0 ?
                                                                                (
                                                                                    initialValues.imagesBackLink !== "" ?
                                                                                        (<div className="image-item">
                                                                                            <img src={process.env.REACT_APP_SERVER_URL + initialValues.imagesBackLink} alt="" width="100" />
                                                                                        </div>)
                                                                                        :
                                                                                        (<svg width={'64px'} height={'64px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M17 17H17.01M15.6 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H8.4M12 15V4M12 4L15 7M12 4L9 7" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                                                                        )
                                                                                )
                                                                                :
                                                                                imagesBack && imagesBack.length > 0 && (
                                                                                    <div className="image-item">
                                                                                        <img src={imagesBack[0].data_url} alt="" width="100" />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </ImageUploading>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='btn-upload-img flex-class justify-content-center'>
                                                                <div className="image-item ">
                                                                    <img src={SVGBack} alt="" width="100" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex-class justify-content-between mt-4 me-2'>
                                                        <div className='btn-step bg-transparent flex-class justify-content-center' onClick={() => setStep('1')}>Quay lại</div>
                                                        <div className='btn-step bg-transparent flex-class justify-content-center' onClick={() => setStep('3')}>Tiếp tục</div>
                                                    </div>
                                                </div>

                                            </Tab>
                                            <Tab eventKey="3" title="3" className='tab-item-user'>
                                                <p>Xem xét</p>
                                                <p className='text-center mt-2'>Nhấn Xong để hoàn thành. Chúng tôi sẽ xem xét thông tin của bạn!</p>
                                                <div className='flex-class justify-content-between mt-4 me-2'>
                                                    <div className='btn-step bg-transparent flex-class justify-content-center' onClick={() => setStep('2')}>Quay lại</div>
                                                    <div className='btn-step bg-transparent flex-class justify-content-center' onClick={() => uploadUserInfor(values)} disabled={load} >
                                                    {load === true ? 'Đang xử lý...' : 'Xác minh'}
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModadlChangeUser;

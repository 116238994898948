import moment from 'moment';

export const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND'
      }).format(price);
};
export  const stripHtmlTags = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};
export const formatDate = (isoDate) => {
  return moment(isoDate).format('YYYY-MM-DD HH:mm:ss');
};
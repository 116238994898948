import React from 'react'
import Image from "../../../asset/img/11-20240323105438-pzl2z.png";
import Support from "../../../asset/img/support-icon-20240323110331-9tdif.svg";
function Content() {
    return (
        <div className='row mt-2 mt-lg-3 g-3'>
        <h1 className='mt-2 mt-lg-3 mb-3 mb-lg-5 text-center'>Chúng tôi đáp ứng tất cả các nhu cầu giao dịch của bạn</h1>
        <div className='col-12 col-lg-5 d-flex align-items-stretch boder-content p-3 p-lg-4  ps-lg-5 pe-lg-5'>
            <div className='ps-4 pe-4 d-flex flex-column'>
                <h4 className='text-center fw-bold fs-4 text-justify'>Hệ thống thanh khoản tự động, hỗ trợ <b className='fw-bold fs-4 color-green3'>200+</b> ngân hàng toàn cầu</h4>
                <img src={Image} className='img-fluid' />
            </div>
        </div>
        <div className='col-12 col-lg-7 d-flex align-items-stretch'>
            <div className='d-flex flex-column w-100 g-3 mt-3 mt-lg-0'>
                <div className='d-flex align-items-stretch mb-3 flex-fill boder-content p-3 p-lg-4  ps-lg-5 pe-lg-5'>
                    <div className='text-center w-100'>
                        <span className='fw-bold fs-4'>Quyền lợi khách hàng</span>
                        <p className='text-justify'>Với chúng tôi, sự thành công vươn mình làm định chế hàng đầu châu á xuất phát từ tin tưởng đồng hành của nhà đầu tư, chúng tôi luôn ưu tiên quyền lợi khách hàng đặt lên hàng đầu trong tất cẩ các danh mục đầu tư</p>
                    </div>
                </div>
                <div className='d-flex align-items-stretch flex-fill p-4 boder-content p-3 p-lg-4  ps-lg-5 pe-lg-5'>
                    <div className='text-center w-100 flex-class justify-content-center'>
                        <div className='flex-class'>
                            <img src={Support} className='img-fluid' />
                        </div>
                        <div className='ms-3'>
                            <span className='fw-bold fs-4'>Hỗ trợ 24/7</span>
                            <p className='text-justify'>Nhận trả lời trong vòng chưa đầy một phút trong trò chuyện trực tiếp hoặc lên lịch gọi lại</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    )
}

export default Content 
import React from 'react';
import Image from "../../../asset/img/logo_en-20240323082117-rpnpr (1).gif";
import Image1 from "../../../asset/img/ceo_e-20240323111213-d3qtg.jpg";
import Image2 from "../../../asset/img/corporate-governance-e-20240323111213-f8a8d.jpg";
import Image3 from "../../../asset/img/2024-03-24-023601-20240324093634-w3cj_.jpg";

function About() {
    return (
        <div className='mt-3 mt-lg-5 w-100'>
            <h2>Về chúng tôi</h2>
            <div className='row g-3 d-flex align-items-stretch'>
                <div className='col-12 col-lg-4 d-flex align-items-center justify-content-center'>
                    <img className='w-80 height-300 img-class-content' src={Image} alt="Description" />
                </div>
                <div className='col-12 col-lg-4 d-flex align-items-stretch'>
                    <div className='row g-2 d-flex align-items-stretch w-100'>
                        <div className='col-12 d-flex align-items-center'>
                            <img className='w-100 img-class-content height-150' src={Image1} alt="Description" />
                        </div>
                        <div className='col-6 d-flex align-items-center'>
                            <img className='w-100 img-class-content' src={Image2} alt="Description" />
                        </div>
                        <div className='col-6 d-flex align-items-center'>
                            <img className='w-100 img-class-content' src={Image3} alt="Description" />
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 d-flex align-items-center'>
                    <video
                        id="VIDEO1_player"
                        className="w-100 height-300"
                        data-video-type="direct"
                        poster="https://w.ladicdn.com/s496x303/642c419b4ebaef00127f95ce/head-jpx-1200-1-20240323111524-p2k7a.jpg"
                        preload="auto"
                        controlsList="nodownload"
                        loop
                        autoPlay
                        playsInline
                        webkit-playsInline
                        controls
                        muted
                        src="https://s.ladicdn.com/642c419b4ebaef00127f95ce/2024-03-23-110714-20240323181539-rhufc.mp4"
                    ></video>
                </div>
                <div className='col-12 mt-3 mt-lg-5 mb-3 mb-lg-5'>
                    <p className='fs-6 text-justify'>Tập đoàn trao đổi Nhật Bản, Inc. JPX EXCHANGE GROUP được thành lập thông qua sự kết hợp kinh doanh giữa Tập đoàn giao dịch chứng khoán Tokyo và Sở giao dịch chứng khoán Osaka vào ngày 1 tháng 1 năm 2013.
                        Vào ngày 1 tháng 10 năm 2019, JPX đã mở rộng kinh doanh sang giao dịch phái sinh hàng hóa bằng cách mua lại Tokyo Commodity Exchange, Inc.

                        JPX EXCHANGE GROUP vận hành các công cụ tài chính trao đổi thị trường để cung cấp cho người dùng thị trường những địa điểm đáng tin cậy để giao dịch các công cụ chứng khoán và phái sinh được liệt kê giao dịch hợp đồng tương lai dựa trên chỉ số <b>BTC/USDT</b> . Ngoài việc cung cấp cơ sở hạ tầng thị trường và dữ liệu thị trường, JPX còn cung cấp dịch vụ thanh toán bù trừ và thanh toán thông qua một đối tác trung tâm và tiến hành giám sát giao dịch để duy trì tính toàn vẹn của thị trường. Trong quá trình làm việc cùng nhau như một nhóm trao đổi để cung cấp một loạt các dịch vụ, chúng tôi tiếp tục nỗ lực để đảm bảo thị trường đáng tin cậy và tạo sự thuận tiện hơn cho tất cả người dùng thị trường.<br/>

                       <b> Ngoài ra chúng tôi nhằm phát triển thị trường trên THẾ GIỚI nói chung và đặc biệt thị trường Việt Nam nói riêng. Giữa quan hệ đối tác chiến lược toàn diện của hai đất nước. Chúng tôi luôn ưu tiên thúc đẩy phát triển nền kinh tế với Việt Nam. Cùng với đó là các gói đầu tư hấp dẫn với LÃI SUẤT CỐ ĐỊNH</b> </p>
                </div>
            </div>
        </div>
    );
}

export default About;

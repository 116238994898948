import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLogin, setModalLogin, setUser , setBalanceUser } from "../../redux/accction/acction";
import APILink from '../../service/APILink';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function Example() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const show = useSelector((state) => state.reducers.modalLogin);
  const [load, setLoad] = useState(false);
  const handleClose = () => dispatch(setModalLogin(false));
  
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Bạn không được bỏ trống email'),

    password: Yup.string()
      .required('Bạn không được bỏ trống mật khẩu'),
  });

  const handleSubmit = (values) => {
    setLoad(true)
    APILink.post('login', values)
      .then((response) => {
        if (response.data.status === "success") {
          Cookies.set('token', response.data.token, { expires: 30, secure: true, sameSite: 'Strict' });          
          toast.success(response.data.message);
          handleClose();
          setTimeout(() => {
            navigate('/home'); 
          }, 800);
        
        }
        else{
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
      setLoad(false)
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className='login-form'>
        <div className='w-100 h-100 position-relative'>
          <div className='icon-close-login-form flex-class justify-content-center' onClick={() => dispatch(setModalLogin(false))}>
              <i className="fa-solid fa-xmark"></i>
          </div>
          <Modal.Header className='pt-4 pb-4'>
            <Modal.Title>Đăng nhập</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <FormikForm noValidate>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                      <Form.Label>Email</Form.Label>
                      <Field
                        type="text"
                        name="email"
                        as={Form.Control}
                        className='input-login-form'
                        required
                      />
                     <div>
                     <ErrorMessage name="email" component="p" className="invalid-feedback d-block" />
                     </div>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                      <Form.Label>Mật khẩu</Form.Label>
                      <Field
                        type="password"
                        name="password"
                        as={Form.Control}
                      required
                        className='input-login-form'                      
                      />
                      <ErrorMessage name="password" component="div" className="invalid-feedback d-block" />
                    </Form.Group>
                  </Row>
                  <Button variant="primary" type="submit" className='btn-login-form bg-transparent mt-4' disabled={load}>
                    {load === true ? 'Đang xử lý...' : 'Đăng nhập'}
                  </Button>

                </FormikForm>
              )}
            </Formik>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Example;

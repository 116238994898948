import React, { useState ,useRef, useEffect} from 'react';
import Logo from "../../asset/img/logo.553c8e5f.png";
import {setModalUser, setSliderBar} from "../../redux/accction/acction";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
function Header() {
    const dispatch = useDispatch();
    const token = Cookies.get('token');
    const statusSliderBar = useSelector((state) => state.reducers.slidebarStatus);
    const balanceUser = useSelector((state) => state.reducers.balanceUser);
    const isLogin = useSelector((state) => state.reducers.isLogin);
    const [status, setStatus] = useState(false);
    const user = useSelector((state) => state.reducers.user);
    const updateModalUser = () => {
        dispatch(setModalUser(true))
    };
    // console.log(statusSliderBar);
    return (
        <header className='flex-class justify-content-between bg-primary-custom mb-1 ps-0'>
            <div className='d-none d-lg-flex logo flex-class justify-content-center'>
                <img className='logo-img' src={Logo} />
            </div>
            <div className='d-block d-lg-none ms-5 flex-class justify-content-center'>
                <i class="fa-solid fa-bars" onClick={()=>dispatch(setSliderBar(!statusSliderBar))}></i>
            </div>
            <div className={`d-lg-flex infro-header flex-class justify-content-center `} >
                <div className='ms-4 '>
                    <p className=''>{user && user.name}</p>
                    <div className='infro-header'>
                        <div className='flex-class justify-content-between' onClick={()=>setStatus(!status)}>
                            <p >$ {balanceUser}</p> <i class="fa-solid fa-chevron-down"></i>
                        </div>
                       {
                            status === true ?
                                (
                                    <div className='infro-header-item'>
                                        <p>Tài khoản giao dịch</p>
                                        <div className='flex-class justify-content-between mt-2'>
                                        <p className=''>$ {balanceUser} </p>
                                            <div className='flex-class justify-content-center card-box'>
                                                <i class="fa-solid fa-credit-card"></i>
                                            </div>
                                        </div>
                                        <hr className='mt-3 h-3' />
                                    </div>
                                ) : null
                        }
                    </div>


                </div>
                {/* <div className='d-none d-lg-flex flex-class justify-content-center ms-4'>
                    <div className='text-center' >
                        <i class="fa-solid fa-gear "></i>
                        <p>
                            Cài đặt
                        </p>

                    </div>
                </div> */}
                <div className='d-none d-lg-flex flex-class justify-content-center ms-4' onClick={updateModalUser} >
                    <div className='text-center'>
                        <i class="fa-sharp-duotone fa-solid fa-user "></i>
                        <p>Hồ sơ</p>
                    </div>
                </div>

            </div>
        </header>
    )
}

export default Header

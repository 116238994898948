import React,{useState, useEffect} from 'react';
import Gauge from 'react-gauge-component';

function GaugeComponent({ title }) {
    const getLabelText = (value) => {
        if (value <= 20) return 'STRONG SELL';
        if (value <= 40) return 'SELL';
        if (value <= 60) return 'NEUTRAL';
        if (value <= 80) return 'BUY';
        return 'STRONG BUY';
    };
    const getColorText = (value) => {
        if (value <= 20) return '#EF5450';
        if (value <= 40) return '#D88F8E';
        if (value <= 60) return '#D1D4DC';
        if (value <= 80) return '#D1D4DC';
        return '#31BAA0';
    };
    const [values, setValues] = useState({ sell: 0, neutral: 0, buy: 0 });


    useEffect(() => {
      const generateRandomValues = () => {
        return {
          sell: Math.floor(Math.random() * 21),
          neutral: Math.floor(Math.random() * 21),
          buy: Math.floor(Math.random() * 21)
        };
      };
      const intervalId = setInterval(() => {
        setValues(generateRandomValues());
      }, 3500);
  
      return () => clearInterval(intervalId);
    }, []);
    //random value between 0 and 100
    const randomVal = Math.floor(Math.random() * 101);
    const chartStyle = {
        width: '100%',
    }
    return (
        <div className='text-center w-100 col-12 col-lg-8'>
            <span className='indicator-title font-weight-custom-bold'>{title}</span>
            <Gauge
                style={chartStyle}
                // className='w-100'
                type="semicircle"
                value={randomVal}
                minValue={0}
                maxValue={100}
                arc={{
                    width: 0.2,
                    padding: 0.005,
                    cornerRadius: 1,
                    subArcs: [
                        {
                            limit: 20,
                            color: '#EF5450',
                            showTick: true,
                            tooltip: {
                                text: 'STRONG SELL'
                            },
                        },
                        {
                            limit: 40,
                            color: '#D88F8E',
                            showTick: true,
                            tooltip: {
                                text: 'Sell'
                            },
                        },
                        {
                            limit: 60,
                            color: '#D1D4DC',
                            showTick: true,
                            tooltip: {
                                text: 'Neutral'
                            }
                        },
                        {
                            limit: 80,
                            color: '#8FF0CB',
                            showTick: true,
                            tooltip: {
                                text: 'Buy'
                            }
                        },
                        {
                            limit: 100,
                            color: '#31BAA0',
                            showTick: true,
                            tooltip: {
                                text: 'STRONG BUY'
                            }
                        }
                    ]
                }}
                pointer={{
                    color: '#345243',
                    length: 0.80,
                    width: 10,
                }}
                labels={{
                    valueLabel: {
                        formatTextValue: () => value => getLabelText(randomVal),
                        style: {
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: value => getColorText(value)
                        }
                    },
                    tickLabels: {
                        type: 'outer',
                        valueConfig: {
                            formatTextValue: value => getLabelText(randomVal),
                            fontSize: 10
                        }
                    }
                }}
                height={200}
                width={500}
                needleColor="#000000"
                arcColor="#00FF00"
                arcWidth={0.3}
                needleWidth={0.1}
                textColor="#000000"
                fontSize={20}
                colors={["#FF5F6D", "#FFC371"]}
            /> 
           <div className='d-none d-lg-flex flex-class justify-content-center'>
                <div className='text-center'>
                    <p className='color-red-2'>{values.sell}</p>
                    <label>Sell</label>
                </div>
                <div className='ms-3 text-center'>
                    <p>{values.neutral}</p>
                    <label>Neutral</label>
                </div>
                <div className='ms-3 text-center'>
                    <p className='color-green-2'>{values.buy}</p>
                    <label>Buy</label>
                </div>
                </div>
        </div>
    );
}

export default GaugeComponent;

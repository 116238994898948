import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setModalNotifation } from "../../redux/accction/acction";
function Code() {
    const dispatch = useDispatch();
    return (
        <div className='modal-infro-user'>
            <div className='modal-infro-user-modal'>
                <div className='modal-content'>
                    <div className='modal-container p-4'>
                        <div className='modal-header flex-class justify-content-between '>
                            <i onClick={() => dispatch(setModalNotifation(false))} class="fa-solid fa-xmark"></i>
                        </div>
                        <div className='code-notification mt-4'>
                            <ul className='w-100 flex-class justify-content-center'>
                                <li className='actice font-bold'>
                                    <p>Mở</p>
                                </li>
                                <li>
                                    <p>Đóng</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Code

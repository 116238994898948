import React from 'react'
import Image1 from "../../../asset/img/screenshot-at-mar-23-04-20-48-20240323112225-bh_ro.png";
import Image2 from "../../../asset/img/screenshot-at-mar-23-04-21-10-20240323112226-w_08p.png";
import Image3 from "../../../asset/img/screenshot-at-mar-23-04-21-02-20240323112225-sqc6r.png";
import Image4 from "../../../asset/img/win-20240112112521-fhwsw.gif";
import Image5 from "../../../asset/img/20-20240112112521-yxno-.gif";
import Image6 from "../../../asset/img/protect-20240112112521--mebj.gif";
import Image7 from "../../../asset/img/people-20240112112521-cqjxc.gif";

function Sesstion2() {
    return (
        <div className='d-none d-lg-block bg-gray2 w-100 p-4'>
            <h4 className='fs-3 text-center mt-5 mb-5'> Thị trường của chúng tôi</h4>
            <div className='row g-1'>
                <div className='col-12 col-lg-4 px-2'>
                    <img src={Image1} className='w-100' />
                </div>
                <div className='col-12 col-lg-4 px-2'>
                    <img src={Image2} className='w-100' />
                </div>
                <div className='col-12 col-lg-4 px-2'>
                    <img src={Image3} className='w-100' />
                </div>
            </div>

            <h4 className='fs-3 text-center mt-5 mb-5'> Vì sao nên chọn JPX EXCHANGE GROUP</h4>
            <div className='row g-1'>
                <div className='col-12 col-lg-3 px-4'>
                    <img src={Image4} className='w-100' />
                    <div className='mt-3'>
                    <span className='color-blue fs-5 fw-bolder'>Nhà môi giới đạt giải thưởng</span>
                    </div>
                </div>
                <div className='col-12 col-lg-3 px-4'>
                    <img src={Image5} className='w-100' />
                    <div className='mt-3'>
                    <span className='color-blue fs-5 fw-bolder'>20+ năm kinh nghiệm</span>
                    </div>
                </div>
                <div className='col-12 col-lg-3 px-4'>
                    <img src={Image6} className='w-100' />
                    <div className='mt-3'>
                    <span className='color-blue fs-5 fw-bolder'>Tăng cường bảo vệ quỹ đầu tư</span>
                    </div>
                </div>
                <div className='col-12 col-lg-3 px-4'>
                    <img src={Image7} className='w-100' />
                    <div className='mt-3'>
                        <span className='color-blue fs-5 fw-bolder'>Hơn 100 ngàn nhà giao dịch</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sesstion2

import React, {useState,useEffect} from 'react'
import ImgUser from "../../asset/img/avavtar.png";
import { useSelector, useDispatch } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ImageUploading from 'react-images-uploading';
import APILink from '../../service/APILink';
import { setModalUser, setModalPassword, setModalChangeUser } from "../../redux/accction/acction";
import { toast } from 'react-toastify';
function Modal() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.reducers.user);
    const [imageShow, setImageNow] = useState(null)
    const [images, setImages] = useState([]);
    const maxNumber = 1;
    useEffect(()=>{
        if(user !== null) {
            setImageNow(process.env.REACT_APP_SERVER_URL + user.avatar);
        }
    },[user])
    const onChange = (imageList) => {
        const img = imageList[0].file;
        const formData = new FormData();
        formData.append('img', img);
        APILink.post('update-avatar',formData)
            .then((response) => {
            if (response.data.status === "success") {
                toast.success(response.data.message)
                setImageNow(process.env.REACT_APP_SERVER_URL + response.data.img);
            }
            })
            .catch((error) => {
            console.error(error);
            });
    };
    return (
        <div className='modal-infro-user'>
            <div className='modal-infro-user-modal'>
                <div className='modal-content'>
                    <div className='modal-container p-4'>
                        <div className='modal-header flex-class justify-content-between '>
                            <h3 className='text-uppercase font-weight-bold'>Hồ sơ</h3>
                            <i onClick={() => dispatch(setModalUser(false))} class="fa-solid fa-xmark"></i>
                        </div>
                        <div className='form-infro-user mt-4'>
                            <div className='flex-class justify-content-start mt-10' style={{ marginTop: '80px' }}  >
                                <div className='modal-image flex-class justify-content-start ms-1'>
                                    <img src={imageShow !== null ? imageShow :  ImgUser} className="" />
                                </div>
                                <div className='ms-3'>
                                    <ImageUploading
                                        multiple={false}
                                        value={images}
                                        onChange={onChange}
                                        maxNumber={maxNumber}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                              imageList,
                                              onImageUpload,
                                              onImageRemoveAll,
                                              onImageUpdate,
                                              onImageRemove,
                                              isDragging,
                                              dragProps,
                                        }) => (
                                            <button className='update-avatar bg-transparent'   onClick={onImageUpload}
                                            {...dragProps}>
                                                Đổi avavtar
                                            </button>
                                        )}
                                    </ImageUploading>

                                </div>
                            </div>

                            <div >
                                <Row className="mb-3">
                                    <div className='mt-3 '>
                                        <h3 className='flex-class justify-content-start'> <i class="fa-regular fa-user me-2"></i>  Thông tin cá nhân</h3>
                                    </div>
                                    <Form.Group as={Col} md="12" className='mt-3 form-user-infro'>
                                        <Form.Label> Tài khoản</Form.Label>
                                        <Form.Control
                                            className='bg-transparent'
                                            type="text"
                                            value={user && user.email}
                                            disabled
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    {/* <Form.Group as={Col} md="12" className='mt-3 form-user-infro '>
                                        <Form.Label>Tên đăng nhập</Form.Label>
                                        <Form.Control
                                            className='bg-transparent'
                                            type="text"
                                            lue={user && user.name}
                                            disabled
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                                </Row>
                                <div className='verification-account'>
                                    <div className='flex-class justify-content-start'>
                                        <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14 19.2857L15.8 21L20 17M4 21C4 17.134 7.13401 14 11 14C12.4872 14 13.8662 14.4638 15 15.2547M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> <span className='ms-2 fs-4'>Xác minh tài khoản</span>
                                    </div>
                                    <div className='mt-4 text-verification'>
                                        <p className='text-justify fs-7'>Để đảm bảo an toàn cho tài sản của bạn, chúng tôi cần xác minh danh tính. Hãy điền thông tin thật chính xác, khi đã hoàn tất xác minh danh tính thì thông tin sẽ không chỉnh sửa được nữa.</p>
                                    </div>
                                    <div className='flex-class justify-content-end mt-3'>
                                        <div className='btn-accuracy' onClick={() => dispatch(setModalChangeUser(true))}>
                                            <p>Xác nhận</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='verification-account'>
                                    <div className='flex-class justify-content-start'>
                                        <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                        <p className='ms-2'>Bảo mật</p>
                                    </div>
                                    <div className='mt-4 text-verification'>
                                        <p className='text-justify fs-7'>Bạn có muốn thay đổi mật khẩu không? Nhấp vào nút phía dưới để thay đổi.</p>
                                    </div>
                                    <div className='flex-class justify-content-end mt-3' >
                                        <div className='btn-accuracy' onClick={() => dispatch(setModalPassword(true))}>
                                            <p>Đổi mật khẩu</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal

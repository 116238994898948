import React ,{useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import APILink from "../../service/APILink";
import { setListMoney, setAmount, setPercentProfit } from "../../redux/accction/acction";
function TablePanelButton() {
    const dispatch = useDispatch();
    const listOrderMoney = useSelector((state) => state.reducers.listOrderMoney);
    const balanceUser = useSelector((state) => state.reducers.balanceUser);
    const amount = useSelector((state) => state.reducers.amount);
    useEffect(()=>{
        getListOrderMoney();
        getDataPercentProfit()
    },[]);
    const getListOrderMoney = () =>{
        APILink.get('order-money')
        .then((response) => {
          if (response.data.status === "success") {
            dispatch(setListMoney(response.data.result));
            dispatch(setAmount(response.data.result[0].amount));
            //dispatch(setPercentProfit(response.data.result[0].amount))
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const getDataPercentProfit = () =>{
        APILink.get('get-first-percent-rofit')
        .then((response) => {
          if (response.data.status === "success") {
            dispatch(setPercentProfit(response.data.profit_percent))
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const plusAmount = () =>{
       const newAmount =Math.ceil(Number(amount) + Number(amount) * 0.1); 
        dispatch(setAmount(newAmount))
    }
    const deductAmount = () =>{
        const newAmount =Math.ceil(Number(amount) - Number(amount) * 0.1); 
         dispatch(setAmount(newAmount))
     }
     const changeValueAmont = (value) =>{
      //if (isNaN(Number(value)) && value >= 0) {
          dispatch(setAmount(value));
     // } 
     }
    return (
        <div className='row'>
            <div className='col-12'>
                <span>Tiền cược</span>
            </div>
            <div className='d-block d-lg-none col-3 mt-3 mb-3'>
                {/* eslint-disable-next-line react/style-prop-object */}
                <button style={{height: 100 + '%'}} className='btn-acction' onClick={deductAmount}>-</button>
            </div>
            <div className='col-6 col-lg-12 mt-3 mb-3'>
                <div className='form-input'>
                    <i class="fa-solid fa-dollar-sign p-icon icon-dollar"></i>
                    <input type='number' min={5} step={1} value={amount}
                           onChange={(e) => changeValueAmont(e.target.value)} className='bg-transparent'/>
                    <i class="fa-regular fa-circle-xmark p-icon icon-close"></i>
                </div>
            </div>
            <div className='d-block d-lg-none col-3 mt-3 mb-3'>
                <button style={{height: 100 + '%'}} className='btn-acction' onClick={plusAmount}>+</button>
            </div>
            <div className='d-none d-lg-block col-6 mt-3 mb-3 px-1 py-1'>
                <button className='btn-acction' onClick={deductAmount}>-</button>
            </div>
            <div className='d-none d-lg-block col-6 mt-3 mb-3  px-1 py-1'>
                <button className='btn-acction' onClick={plusAmount}>+</button>
            </div>
            {listOrderMoney && listOrderMoney !== null && listOrderMoney.map((item) => {
                return (
                    <div className='d-none d-lg-block col-4 px-1 py-1'>
                        <button className='btn-acction'
                                onClick={() => dispatch(setAmount(item.amount))}>${item.amount}</button>
                    </div>
                )
            })}
            <div className='d-none d-lg-block col-4 px-1 py-1'>
                <button className='btn-acction' onClick={() => dispatch(setAmount(balanceUser))}>All</button>
            </div>

        </div>
    )
}

export default TablePanelButton

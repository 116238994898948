import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./component/header/header";
import SlideBar from "./component/slidebar/slidebar";
import Main from "./page/main";
import ModalUser from "./component/header/modal";
import ModalLogin from "./component/modal/login";
import ModalRegister from "./component/modal/register";
import ModelPassword from "./component/modal/updatePassword";
import ModelCode from "./component/modal/code";
import ModalPay from './component/main/payModal';
import ChangeUser from './component/modal/changeUser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './asset/css/app.css';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setUser, setBalanceUser ,setSliderBar,setIsCallAPi} from "./redux/accction/acction";
import React, { useState, useEffect, useRef } from 'react';
import APILink from './service/APILink';
import { useLocation } from 'react-router-dom';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathAfterDomain = location.pathname;
  const modelUser = useSelector((state) => state.reducers.modelUser);
  const modalChangeUser = useSelector((state) => state.reducers.modalChangeUser);
  const modalLogin = useSelector((state) => state.reducers.modalLogin);
  const modalRegister = useSelector((state) => state.reducers.modalRegister);
  const codeStatus = useSelector((state) => state.reducers.codeStatus);
  const isCallApi = useSelector((state) => state.reducers.isCallApi);
  const token = Cookies.get('token');
  const sidebarRef = useRef(null);
  useEffect(() => {
    if (token || isCallApi === true) { 
      getDatainforUser();
    }
  }, [token, isCallApi]);

  const getDatainforUser = () => {
    dispatch(setBalanceUser("Loading..."))
    APILink.get('user')
      .then((response) => {
        if (response.data.status === "success") {
          dispatch(setUser(response.data.user));
          dispatch(setBalanceUser((Math.floor(response.data.balance * 100) / 100).toFixed(2)));
        }
      })
      .catch((error) => {
        console.error(error);
      }).finally(()=>{
        dispatch(setIsCallAPi(false))
      });
  };
  const handleClickOutside = (event) => { 
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      dispatch(setSliderBar(false));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className='app'>
      <ToastContainer />
      <div className={`body-web p-0 container-fluid ${pathAfterDomain !== '/' && pathAfterDomain !== '/chat'  ? 'bg-black color-white' : "bg-white color-black ps-3 pe-3 pt-3"}`}>
        {pathAfterDomain !== '/'  && pathAfterDomain !== '/chat' ? (
          <>
            {modelUser === true && <ModalUser />}
            {modalChangeUser === true && <ChangeUser />}
            {codeStatus && <ModelCode />}
            <ModelPassword />
            <Header />
            <ModalPay />
          </>
        ) : (
          <>
            {modalLogin ===true && <ModalLogin />}
            {modalRegister  ===true && <ModalRegister />}
          </>
        )}
        <main className={`d-flex bg-primary-customs pt-5 pt-lg-0 ${ pathAfterDomain === '/chat' && 'chat'}`}>
          {pathAfterDomain !== '/'  && pathAfterDomain !== '/chat'  && 
          
          (
            <SlideBar ref={sidebarRef} />
          )}
          <section className={`${pathAfterDomain !== '/' ? "main-custom main-container p-4 plg-0" : "w-100"}`}>
            <Main />
          </section>
        </main>
      </div>
    </div>
  );
}

export default App;

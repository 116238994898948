import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import APILink from '../../service/APILink';
import { toast } from 'react-toastify';
import { formatDate } from "../../service/funWeb";
import {setIsCallAPi} from "../../redux/accction/acction";
import { useSelector, useDispatch } from 'react-redux';
function TableComponent() {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);

    const titlerLable = [
        'Ngày mua',
        'Ngày nhận',
        'Tên máy đào',
        'Giá',
        'Lợi nhuận',
        'Trạng thái'
    ];

    useEffect(() => {
        callAPiFun();
    }, []);

    const callAPiFun = () => {
        APILink.get('history-mimer')
            .then((response) => {
                if (response.data.status === 'success') {
                    setData(response.data.result);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleClick = (item) => {
        if (item.status === 0) {
            withdrawMoney(item.id);
        }
    };

    const withdrawMoney = (id) => {
        if (!load) {
            setLoad(true);
            const value = { id };

            APILink.post('withdraw-money', value)
                .then((response) => {
                    if (response.data.status === 'success') {
                        toast.success(response.data.message);
                        callAPiFun();
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoad(false);
                    dispatch(setIsCallAPi(true))
                });
        }
    };

    return (
        <div className="table-responsive">
            <Table className="table">
                <thead>
                    <tr>
                        {titlerLable.map((item, index) => (
                            <th key={index}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <tr key={index}>
                                <td>{formatDate(item.created_at)}</td>
                                <td>{item.expire}</td>
                                <td>{item.name}</td>
                                <td>${item.amount}</td>
                                <td>${item.profit}</td>

                                <td>
                                    {true == true ? console.log(new Date(item.expire) < new Date()) : ""}
                                    {item.expire < new Date() && console.log("dsasad")}
                                    
                                    <div className='flex-class justify-content-center p-2'>
                                        {(new Date(item.expire) < new Date() ) === false? (
                                            
                                            <span>Đang đào</span>
                                        ) : (
                                            <span
                                                className={`badge ${item.status === 0 ? 'bg-green3' : 'bg-red-2'}`}
                                                onClick={() => handleClick(item)}
                                                style={{ cursor: item.status === 0 ? 'pointer' : 'default' }}
                                            >
                                                {item.status === 0 ?
                                                    (

                                                        'Rút ngay'
                                                    )
                                                    : 'Đã rút'}
                                            </span>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className='text-center'>Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>

    );
}

export default TableComponent;

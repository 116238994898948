import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PrimaryPay from '../component/main/primaryPay';
import TransactionPay from '../component/main/transactionPay';
import { useSelector, useDispatch } from 'react-redux';

function Pay() {
    const balanceUser = useSelector((state) => state.reducers.balanceUser);
    return (
        <div>
            <div className='total-money pt-3 ps-5 pe-4 pb-5 w-100'>
                <span className='mt-3'>Tổng tài sản</span>
                <h3 className='mt-4'>$ {balanceUser}</h3>
            </div>
            <div className='tab-content mt-5 container'>
                <Tabs
                    defaultActiveKey="primaryPay"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="primaryPay" title="Ví chính" className='mt-5'>
                       <PrimaryPay/>
                    </Tab>
                    {/*<Tab eventKey="transactionPay" title="Ví giao dịch" className='mt-5'>*/}
                    {/*   <TransactionPay/>*/}
                    {/*</Tab>*/}
                </Tabs>
            </div>
        </div>
    )
}

export default Pay

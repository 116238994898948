import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BuyExcavator  from "../component/excavator/buyExcavator";
import History  from "../component/excavator/history";
function Excavator() {

  return (
    <div className='w-100 flex-class justify-content-center mt-5 mt-lg-0'>
      <div className='container-fluid container-lg  bg-primary-custom excavator height-auto p-0 pt-4 pb-4 p-lg-4 pt-lg-5 pb-lg-5'>
      <Tabs
      defaultActiveKey="buy"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="buy" title="Mua máy đào">
        <BuyExcavator/>
      </Tab>
      <Tab eventKey="history" title="Lịch sử mua">
       <History/>
      </Tab>
    </Tabs>
      </div>
    </div>
  )
}

export default Excavator
import React,{useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import APILink from "../../service/APILink";
import { toast } from 'react-toastify';
import { setModalPay, setIsCallAPi } from "../../redux/accction/acction";
import {formatPrice} from "../../service/funWeb"
function RechargeModalComponent() {
    const dispatch = useDispatch();
    const charge_description = useSelector((state) => state.reducers.charge_description);
    const send_money = useSelector((state) => state.reducers.send_money);
    const rate = useSelector((state) => state.reducers.rate);
    const [money, setMoney] = useState(null);
    const [load, setLoad] = useState(false);
    const [iamge,  setImage] = useState(null);

    const setValueMoney =(value)=>{
      setMoney(value);
    }

    const onchangeImage = (event)=>{
        setImage(event.target.files[0]);
    }

    const postRecharge = () => {
        if (Number.isInteger(Number(money)) && Number(money) > 4) {
            const formData = new FormData();
            //if image not exist then show error and return
            if(iamge === null){
                toast.error('Vui lòng chọn ảnh');
                return;
            }
            formData.append('image', iamge);
            formData.append('amount', Number(money));
            setLoad(true)
            APILink.post('recharge', formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then((response) => {
                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    setMoney(null);
                    dispatch(setModalPay(false));
                    setLoad(false);
                    window.location.reload();
                }
              })
              .catch((error) => {
                console.error(error);
              }).finally(()=>{
                dispatch(setIsCallAPi(true))
              });
        }else{
            toast.error('Vui lòng nạp số tiền lớn hơn 5');
        }
      }
  return (
    <div>
        <div>
            <div className='input-recharge'>
                    <input placeholder='Nhập số tiền cần nạp' min="1" value={money} onChange={(e)=>setValueMoney(e.target.value)} type='number' className='bg-transparent' />
                    <label>Giá trị USD</label>
            </div>
            <div className='mt-3 mb-3'>
                  <label>Tải ảnh lên</label>
                    <input required type="file" className='bg-transparent text-white ms-3' onChange={(e)=>onchangeImage(e)} />
            </div>
            <div className='text-infor text-center mt-4 color-white line-height-custom-1-8'>
             <div dangerouslySetInnerHTML={{ __html: send_money }} />
                <p>Với số tiền là {formatPrice(rate*Number(money))}</p>
                <div dangerouslySetInnerHTML={{ __html: charge_description }} />
                <button className='mt-3 btn-recharge' onClick={postRecharge} disabled={load}>
                    {load === true ? 'Đang xử lý...' : 'Nạp'}
                </button>
            </div>
        </div>
    </div>
  )
}

export default RechargeModalComponent
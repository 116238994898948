import React from 'react';
import Table from 'react-bootstrap/Table';
import { useSelector, useDispatch } from 'react-redux';

function TableComponent({ titlerLable, data, type }) {
    const loaddingHistiry = useSelector((state) => state.reducers.loaddingHistiry);

    return (
        <Table>
            <thead>
                <tr>
                    {titlerLable && titlerLable.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
            {
                    loaddingHistiry === true ?
                        (<tr>
                            <td className='text-center'>Đang load dữ liệu</td>
                        </tr>)
                        : 
                (data && data.length > 0 ? (
                    data.map((item, index) => (
                        <tr key={index}>
                            <td>{new Date(item.created_at).toLocaleString()}</td>
                            <td>{type === false ? "Nạp" : "Rút"} </td>
                            <td>$ {item.amount}</td>
                            <td>
                                {
                                    type === false ?
                                        (item.is_approve === 0 ? "Chờ duyệt" : "Đã duyệt")
                                        :
                                        (item.is_approve === 0 ? "Chưa xác nhận" : "Đã xác nhận")
                                }
                            </td>
                        </tr>
                    ))
                    ) : (
                        <tr>
                        <td className='text-center'>Không có dữ liệu</td>
                    </tr>
                )
            )  }
            </tbody>
        </Table>
    );
}

export default TableComponent;

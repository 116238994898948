import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';
import { setModalPassword } from "../../redux/accction/acction";
import APILink from '../../service/APILink';
import { toast } from 'react-toastify';
function Example() {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.reducers.modalPassword);
  const handleClose = () => dispatch(setModalPassword(false));
  const [validated, setValidated] = useState(false);
  const [passwordOld, setPasswordOld] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [apasswordNew, setAPasswordNew] = useState('');
  const [load, setLoad] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const changePasswordFun = () =>{
    alert('32131');
    if(apasswordNew === passwordNew){
      const data ={
        passwordOld : passwordOld,
        passwordNew : passwordNew
      }
      setLoad(true)
      APILink.post('change-password', data)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          dispatch(setModalPassword(false));
       
        }else{
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
    else{
      toast.error("xác nhận mật khẩu mới và mật khẩu mới phải trùng khớp");
    }
    setLoad(false)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} className='update-password'>
        <div className='w-100 h-100 position-relative'>
          <div className='icon-close-update-password flex-class justify-content-center' onClick={()=>dispatch(setModalPassword(false))}>
              <i class="fa-solid fa-xmark "></i>
          </div>
            <Modal.Header  className='pt-4 pb-4'>
            <Modal.Title>Đổi mật khẩu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                  <Form.Label>Mật khẩu cũ</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    className='input-update-password'
                    onChange={(e)=>setPasswordOld(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                  <Form.Label>Mật khẩu mới</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    className='input-update-password'
                    onChange={(e)=>setPasswordNew(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                  <Form.Label>Xác nhận mật khẩu</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    className='input-update-password'
                    onChange={(e)=>setAPasswordNew(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className='btn-update-password bg-transparent' onClick={changePasswordFun} disabled={load}>
            {load === true ? 'Đang xử lý...' : 'Đổi mật khẩu'}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default Example;
import React from 'react'
import FooterImage from "../../asset/img/screenshot-at-mar-23-04-57-04-20240323115729-lrl8q.png";
function Footer() {
  return (
    <div className=' h-100 row'>
          <div className='col-12 p-0 w-100 h-100'>
              <img className='w-100' src={FooterImage} />
          </div>
    </div>
  )
}

export default Footer
import React from 'react'
import Image from "../../../asset/img/screenshot-at-mar-23-04-50-53-20240323115114-x-xcy.png";
import Image1 from "../../../asset/img/gd-20240112141954-ejpwu.png";
function Session3() {
    return (
        <div className='row mt-3 mb-3'>
            <div className='col-12 col-lg-6 d-lg-flex align-items-stretch'>
                <img className='image-s3 w-100' src={Image} />
            </div>
            <div className='col-12 col-lg-6 d-lg-flex align-items-stretch'>

              <div className='h-100 w-100 flex-class justify-content-center'>
                   <div>
                   <img src={Image1} />
                <h4 className='fs-3 text-centr mt-4 mb-3'>
                    Hãy nắm bắt mọi cơ hội
                </h4>
                <p className='fs-6 text-centr'>
                    Giao dịch trực tuyến mọi lúc, mọi nơi. Trên web, thiết bị di động và máy tính để bàn.
                </p>
                   </div>
              </div>
            </div>
        </div>
    )
}

export default Session3
